import React, {Suspense} from "react";
import {Outlet} from "react-router-dom";
import RootLoader from "../../components/RootLoader/RootLoader.tsx";
import Config from "../../lib/Config.ts";

const BookingUrl: React.FC = () => {
    return (
        <>
            <div className="container">
                <Suspense fallback={<RootLoader />}>
                    <Outlet />
                </Suspense>
            </div>

            <div className="build">{Config.data.build}</div>
        </>
    )
}

export default BookingUrl
