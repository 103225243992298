import React from "react";
import {useRestaurant} from "../../lib/state.ts";
import styles from "./HeaderImage.module.css";

export const HeaderImageLoader: React.FC = () => {
    return <div className={styles.image} style={{backgroundColor: '#DFDFDF'}}></div>
}

const HeaderImage: React.FC = () => {
    const {restaurant} = useRestaurant();

    if (!restaurant) {
        return <HeaderImageLoader />
    }

    return <img src={restaurant.wide_crop_image_url || restaurant.tall_crop_image_url} className={styles.image} alt="" width="100%" height={200} />;
}

export default HeaderImage