import React from 'react';
import { useRemainingTime } from '../lib/state.ts';

import styles from './RemainingTime.module.css';

const RemainingTime: React.FC = () => {
  const { remaining, show_countdown } = useRemainingTime();

  if (!remaining) {
    return <></>;
  }

  if (show_countdown) {
    return (
      <div className="section">
        <div className={styles.time}>
          Remaining Time to Confirm: {remaining}
        </div>
      </div>
    );
  }

  return (
    <div className="section">
      <div className={styles.time}>This link expires on {remaining}</div>
    </div>
  );
};

export default RemainingTime;
