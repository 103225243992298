import * as Sentry from '@sentry/react';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header.tsx';
import { AuthResponse } from '@/lib/types.ts';
import { verification, verify } from '@/lib/api.ts';
import { AuthAtom, PhoneAtom, useDirectLinkState } from '../lib/state.ts';
import debounce from 'lodash/debounce';
import styles from './Auth.module.css';
import OtpInput from 'react-otp-input';

const Auth: React.FC = () => {
  const navigate = useNavigate();
  const phone = useAtomValue(PhoneAtom);
  const [sending, setSending] = useState<boolean | null>(null);
  const [, setDirectLink] = useDirectLinkState();
  const setAuth = useSetAtom(AuthAtom);
  const [confirmed, setConfirmed] = useState<boolean | null>(null);
  const [otp, setOtp] = useState('');

  useEffect(() => {
    if (!phone) {
      navigate('../phone');
    }
  }, []);

  const onResendClick = (event: React.SyntheticEvent) => {
    // es-lint is finicky about promises and event handlers
    event.preventDefault();
    void onResend('');
  };

  const onResendViaWhatsApp = (event: React.SyntheticEvent) => {
    // es-lint is finicky about promises and event handlers
    event.preventDefault();
    void onResend('whatsapp');
  };

  const onResend = async (channel) => {
    if (!phone) {
      return;
    }

    setSending(true);

    await verify(phone.formatInternational()!, channel);

    setSending(false);

    setTimeout(() => setSending(null), 5000);
  };

  useEffect(() => {
    if (otp.length === 4) {
      debouncedAuth(otp);
    }
  }, [otp]);

  const onComplete = async (code: string) => {
    if (code.length < 4) {
      return;
    }

    if (!phone) {
      return;
    }

    try {
      const verification_response: AuthResponse = await verification(
        phone.formatInternational()!,
        code
      );

      Sentry.setUser({
        id: verification_response.user,
      });

      setAuth(verification_response);
      setDirectLink({
        unauthorized_error: null,
      });

      setConfirmed(true);

      setTimeout(() => navigate(`../accept`), 300);
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.error(e.message);
        Sentry.captureException(e);
      }

      setConfirmed(false);
      setOtp('');
    }
  };

  const debouncedAuth = useCallback(debounce(onComplete, 300), []);

  if (!phone) {
    return <></>;
  }

  return (
    <>
      <Header title="Verify your phone number" step={0} show_image={false} />

      <div className="content">
        <div className="section">
          <h3>
            We sent a 4-digit code to{' '}
            <span className={styles.noWrap}>{phone.formatNational()}</span>
          </h3>

          <p className={styles.info}>Enter the 4-digit code</p>

          <div className={styles.pinFieldContainer}>
            <OtpInput
              shouldAutoFocus
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderInput={(props) => <input {...props} />}
              inputStyle={styles.pinField}
              skipDefaultStyles
              inputType="number"
            />
          </div>

          {confirmed === false && (
            <div className={styles.error}>
              The code you entered is incorrect
            </div>
          )}

          {sending === true && (
            <a href="/" className={styles.again} onClick={onResendClick}>
              Sending...
            </a>
          )}
          {sending === false && (
            <a href="/" className={styles.again}>
              Sent
            </a>
          )}
          {sending === null && (
            <a href="/" className={styles.again} onClick={onResendClick}>
              Send again via SMS
            </a>
          )}

          {sending === null && (
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
              <a
                href="/"
                className={styles.again}
                onClick={onResendViaWhatsApp}
              >
                Send via WhatsApp
              </a>
            </div>
          )}

          <button className="nextButton" disabled={!confirmed}>
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};

export default Auth;
