import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import React, { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header.tsx';
import RemainingTime from '../RemainingTime/RemainingTime.tsx';
import { ReservationAtom, useDirectLinkState } from '../lib/state.ts';
import styles from './Accept.module.css';
import NeedToKnows from '@/components/NeedToKnows/NeedToKnows.tsx';
import DirectionsIcon from '@/assets/icons/Back_light.svg?react';
import GlobeIcon from '@/assets/icons/globe_light.svg?react';
import { useMediaQuery } from 'react-responsive';
import { getMapUrl } from '@/lib/getMapUrl.ts';

const Accept: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
  const reservation = useAtomValue(ReservationAtom);
  const navigate = useNavigate();
  const [, setDirectLink] = useDirectLinkState();

  const onAccept = () => {
    setDirectLink({
      reservation_accepted: true,
    });
    navigate('../Info');
  };

  if (!reservation) {
    return (
      <>
        <Header title="Loading confirmation" step={0} />
        <div className="content">
          <p>Please wait...</p>
        </div>
      </>
    );
  }

  const isEvent = reservation?.restaurant.type === 'event';
  const flat_address = reservation?.restaurant.address
    ? reservation?.restaurant.address.replace(/[\r\n]+/, '')
    : '';
  const width = isMobile ? 800 : 258;
  const height = isMobile ? 427 : 263;
  const map_url = getMapUrl(width, height, reservation?.restaurant, isRetina);

  const eventDayTime = `${reservation.reservation_at_tzd.format(
    'ddd, MMMM D'
  )} - ${reservation.reservation_at_tzd.format('h:mm a')}`;

  return (
    <>
      <Header
        title={`${reservation.restaurant.name}`}
        subtitle={eventDayTime}
        step={0}
        isEvent={isEvent}
      />

      <div className="content">
        {isEvent ? (
          <p>
            <span>Please accept your table for {reservation.restaurant.name}.</span><br/>
            <span>Ticket type: {reservation.table_type}.</span>
          </p>
        ) : (
          <p>
            <span>Please accept your Dorsia reservation at {reservation.restaurant.name}.</span><br/>
            <span>Table type: {reservation.table_type}.</span>
          </p>
        )}

        {!isEvent && (
          <p>
            Dorsia is a booking platform with access to the most in-demand
            restaurant reservations and culinary experiences.
          </p>
        )}
        <div className={styles.infowrapper}>
          <div>
            <div className={clsx('section', styles.eventdetail)}>
              <div>{reservation.reservation_at_tzd.format('ddd, MMMM D')}</div>
              <div>{reservation.reservation_at_tzd.format('h:mm a')}</div>
              {reservation.table_type ? (
                <div>
                  {reservation.table_type}, {reservation.party_size} guests
                </div>
              ) : (
                <div>{reservation.party_size} guests</div>
              )}

              <div className={styles.minimum}>
                {reservation.table_minimum__currency}
              </div>
            </div>

            <div className={clsx('section', styles.locationdetail)}>
              <h3>Details</h3>

              <div className={styles.address}>
                <Address address={reservation.restaurant.address} />
              </div>

              {isMobile && (
                <div className={styles.mapContainer}>
                  {map_url && reservation?.restaurant.address && (
                    <a
                      href={`https://maps.google.com/?q=${flat_address}`}
                      target="_blank"
                    >
                      <img
                        className={styles.map}
                        src={map_url.toString()}
                        alt="Map"
                      />
                    </a>
                  )}
                </div>
              )}

              {isMobile && reservation?.restaurant.address && (
                <div className={styles.mapinfo}>
                  <div className={styles.mapinfoblock}>
                    <a
                      href={`https://maps.google.com/?q=${flat_address}`}
                      target="_blank"
                    >
                      <DirectionsIcon className={styles.infoIcon} />
                    </a>
                    <a
                      href={`https://maps.google.com/?q=${flat_address}`}
                      target="_blank"
                    >
                      Directions
                    </a>
                  </div>
                  {reservation.restaurant.website && (
                    <div className={styles.mapinfoblock}>
                      <GlobeIcon className={styles.infoIcon} />
                      <a href={reservation.restaurant.website} target="_blank">
                        Website
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {!isMobile && (
            <div className={styles.mapContainerDesk}>
              {map_url && reservation?.restaurant.address && (
                <a
                  href={`https://maps.google.com/?q=${flat_address}`}
                  target="_blank"
                >
                  <img
                    className={styles.map}
                    src={map_url.toString()}
                    alt="Map"
                  />
                </a>
              )}
            </div>
          )}
        </div>
        <hr />

        <div className={clsx('section', styles.needToKnow)}>
          <h3>Need to know</h3>

          <div className={styles.needToKnowTip}>
            <Suspense fallback={<Loading />}>
              <NeedToKnows
                need_to_knows={reservation.restaurant.need_to_knows}
              />
            </Suspense>
          </div>
        </div>

        <div className="floating section">
          <button className="nextButton" onClick={onAccept}>
            Accept reservation
          </button>
        </div>

        <RemainingTime />
      </div>
    </>
  );
};

const Loading: React.FC = () => {
  return <div>Wait...</div>;
};

interface AddressProps {
  address: string;
}

const Address: React.FC<AddressProps> = ({ address }) => {
  const split = address.replace('\r', '').split('\n');

  return (
    <>
      {split.map((line: string, i: number) => (
        <div key={i}>{line}</div>
      ))}
    </>
  );
};

export default Accept;
