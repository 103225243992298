import React from "react";
import NeedToKnowIcon, {NeedToKnowNames} from "../NeedToKnowIcon/NeedToKnowIcon.tsx";
import {NeedToKnow} from "../../lib/types.ts";
import styles from './NeedToKnows.module.css';

interface Props {
    need_to_knows: NeedToKnow[]
}

export const NeedToKnows: React.FC<Props> = ({need_to_knows}) => {
    if (need_to_knows.length === 0) {
        return <></>;
    }

    return (
        <>
            {
                need_to_knows.map((need_to_know: NeedToKnow, i: number) =>{
                    return(
                        <div key={i} className={styles.needToKnow}>
                            <NeedToKnowIcon name={need_to_know.icon as NeedToKnowNames} />
                            {need_to_know.description}
                        </div>
                    )
                })
            }
        </>
    )
}

export default NeedToKnows;