import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import React, { Suspense, useState } from 'react';
import PhoneInputWithCountrySelect, { Country } from 'react-phone-number-input';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Header from '../Header/Header.tsx';
import { ApiError } from '../../../lib/ApiError.ts';
import Config from '../../../lib/Config.ts';
import {
  GetReservation,
  ReserveErrorResponse,
  UpdateUserErrorResponse,
} from '../../../lib/types.ts';
import RestaurantName, {
  RestaurantNameLoader,
} from '../RestaurantName/RestaurantName.tsx';
import {
  useRestaurantId,
  updateBookingUrlStateAtom,
  reloadResaurantKey,
  useResetState,
  UrlStateAtom,
} from '../lib/state.ts';

import {
  ReservationDates,
  ReservationDatesLoader,
} from './ReservationDates.tsx';
import {
  ReservationTimes,
  ReservationTimesLoader,
} from './ReservationTimes.tsx';
import ReservationTables from './ReservationTables.tsx';

import 'react-phone-number-input/style.css';
import styles from './Booking.module.css';

dayjs.extend(utc);
dayjs.extend(timezone);

const Booking: React.FC = () => {
  const id = useRestaurantId();
  const resetState = useResetState();
  const [temp, setTemp] = useAtom(updateBookingUrlStateAtom);
  const [error, setError] = useState<string | undefined>();
  const [url, setUrl] = useAtom(UrlStateAtom);

  const booking_ready =
    !!id &&
    !!temp.date &&
    !!temp.availability &&
    !!temp.option &&
    !!temp.phone_number;

  const onNext = () => {
    if (booking_ready) {
      const go = async () => {
        try {
          setError(undefined);

          console.log('TEMP', temp.availability);
          const options: Post_BookingLink = {
            restaurant: id,
            seating: temp.option!.seating_id,
            seats: temp.option!.seats,
            date: temp.availability!.datetime_utc,
            phone: temp.phone_number!,
            confirmed_price: temp.option!.price,
          };

          const { data } = await createBookingLink(options);

          if (data.uuid) {
            setUrl({
              url: `${Config.data.uri.app}reservation/${data.uuid}?phone=${temp.phone_number}`,
              state: temp,
            });
            resetState();
          } else {
            setError(
              'Got a valid response, but No UUID.  See console for details'
            );
            console.warn('No UUID', data);
          }
        } catch (e: unknown) {
          if (e instanceof ApiError && e.type === 'ServerErrorResponse') {
            console.warn('Server Error - Reservation Likely Taken');

            reloadResaurantKey();
            setTemp({
              option: undefined,
            });

            setError('Server Error - Reservation Likely Already Booked');
          } else if (e instanceof Error) {
            setError(e.message);
            Sentry.captureException(e);
          }
        }
      };

      void go();
    }
  };

  const onPhoneCountryChange = (country: Country) => {
    setTemp({
      phone_country: country,
    });
  };

  const onPhoneNumberChange = (number: string | undefined) => {
    setTemp({
      phone_number: number,
    });
  };

  const onClear = () => {
    setUrl({});
    resetState();
  };

  return (
    <>
      <Header title="Generate booking link" step={0} />

      {error && (
        <div className={clsx('content', styles.header_error)}>{error}</div>
      )}

      <div className="content">
        <Suspense fallback={<RestaurantNameLoader />}>
          <RestaurantName />
        </Suspense>

        {!url?.url && (
          <>
            <div className={clsx('section', styles.section)}>
              <h3>Select a date</h3>

              <Suspense fallback={<ReservationDatesLoader />}>
                <ReservationDates />
              </Suspense>
            </div>

            {!!temp.date && (
              <div className={clsx('section', styles.section)}>
                <h3>Select a time</h3>

                <Suspense fallback={<ReservationTimesLoader />}>
                  <ReservationTimes key={temp.date} />
                </Suspense>
              </div>
            )}

            {!!temp.date && !!temp.availability && (
              <div className={clsx('section', styles.section)}>
                <h3>Select a table</h3>

                <Suspense fallback={<ReservationTimesLoader />}>
                  <ReservationTables
                    key={`${temp.date}-${temp.availability.uid}`}
                  />
                </Suspense>
              </div>
            )}

            <div className="section">
              <div className={styles.field}>
                <label>Phone number</label>
                <PhoneInputWithCountrySelect
                  placeholder="Enter phone number"
                  value={temp.phone_number ?? ''}
                  country={temp.phone_country ?? 'US'}
                  defaultCountry="US"
                  onChange={onPhoneNumberChange}
                  onCountryChange={onPhoneCountryChange}
                />
              </div>
            </div>

            <div className="section">
              <button
                className="nextButton"
                onClick={onNext}
                disabled={!booking_ready}
              >
                Next
              </button>
            </div>
          </>
        )}

        {url?.url && url?.state && (
          <div className="section">
            <div>
              {dayjs(url.state.availability?.datetime_utc).format(
                'ddd, MMMM D'
              )}
            </div>
            <div>
              {dayjs(url.state.availability?.datetime_utc).format('h:mm a')}
            </div>
            <div>{url.state.option?.seats} guests</div>
            <div>{url.state.option?.type}</div>
            <div>{url.state.option?.price__currency} minimum per person</div>
            <div>{url.state.phone_number}</div>

            <a href={url.url} target="_blank" className="nextButton">
              Reservation Link
            </a>
          </div>
        )}

        <div className="resetArea">
          <button onClick={onClear} className="resetButton">
            Start Over
          </button>
        </div>
      </div>
    </>
  );
};

interface CreateReservationResponse {
  data: GetReservation;
}

interface Post_BookingLink {
  restaurant: number;
  seating: number;
  seats: number;
  date: string;
  confirmed_price: number;
  phone: string;
}

const createBookingLink = async (
  body: Post_BookingLink
): Promise<CreateReservationResponse> => {
  const url = new URL(
    'https://staging.dorsia.com/internal/reservations/on-behalf'
  );

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-API-Version': '20230918',
    Authorization: 'Bearer b19e538b-6768-46bf-b3c3-3b933d28b425',
  };

  const options: RequestInit = {
    method: 'POST',
    mode: 'cors',
    headers,
    body: JSON.stringify(body),
  };

  let json: CreateReservationResponse = {} as CreateReservationResponse;
  let response: Response | null = null;
  try {
    response = await fetch(url, options);
  } catch (e: unknown) {
    console.warn(
      'Uncaught Exception',
      url.toString(),
      options,
      e instanceof Error ? e.message : ''
    );
  }

  if (response) {
    try {
      json = (await response.json()) as CreateReservationResponse;
    } catch (e: unknown) {
      console.warn('Json Parsing issue', url.toString());
    }

    if (!response.ok) {
      const type = ApiError.match<CreateReservationResponse>(json);
      if (type) {
        const resp = json as unknown as
          | ReserveErrorResponse
          | UpdateUserErrorResponse;
        throw new ApiError({ url, response: resp });
      }

      throw new Error(response.statusText);
    }
  }

  return json;
};

export default Booking;
