import clsx from 'clsx';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Header/Header.tsx';
import Config from '../../../lib/Config.ts';
import { GetReservation } from '../../../lib/types.ts';
import {
  PaymentMethodsAtom,
  reloadPaymentMethodsAtom,
  ReservationAtom,
} from '../lib/state.ts';
import PaymentPicker from './PaymentMethods.tsx';
import RemainingTime from '../RemainingTime/RemainingTime.tsx';
import { AuthAtom, useDirectLinkState } from '../lib/state.ts';
import { setPaymentMethod, setupIntent } from '../../../lib/api.ts';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  loadStripe,
  Stripe,
  StripeElementsOptions,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import * as Sentry from '@sentry/react';

import styles from './Payment.module.css';

const Payment: React.FC = () => {
  const reservation = useAtomValue(ReservationAtom);
  const [directLink] = useDirectLinkState();
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const methods = useAtomValue(PaymentMethodsAtom);

  useEffect(() => {
    const waitForIt = async () => {
      setStripe(await loadStripe(Config.data.stripe.key));
    };

    void waitForIt();
  }, []);

  const stripe_options: StripeElementsOptions = {
    mode: 'setup',
    currency: 'usd',
    appearance: {
      theme: 'stripe',

      variables: {
        fontFamily: 'Sailec',
        borderRadius: '10px',
        colorBackground: '#FFFFFF',
        spacingGridColumn: '30px',
        spacingGridRow: '30px',
      },

      rules: {
        '.Label': {
          fontSize: '16px',
          marginBottom: '15px',
          fontFamily: 'Sailec',
        },

        '.Input': {
          boxShadow: 'none',
          padding: '10px',
          fontSize: '16px',
          fontFamily: 'Sailec',
        },
      },
    },
    fonts: [
      {
        family: 'Sailec',
        src: 'url(https://book.dorsia.com/fonts/stripe-font.otf)',
      },
    ],
  };

  if (!reservation || !stripe) {
    return (
      <>
        <Header
          title="Sorry, issue with loading Reservation"
          step={2}
          show_image={false}
        />
      </>
    );
  }

  return (
    <>
      <Header
        title="Add payment method"
        step={2}
        show_image={false}
        isEvent={reservation.restaurant.type === 'event'}
      />

      {directLink?.payment_error && (
        <div className={clsx('content', styles.header_error)}>
          There was an issue with your payment method.
        </div>
      )}

      <div className="content">
        <h2>{reservation.restaurant?.name}</h2>

        <div className={styles.grid}>
          <div className="section">
            <h3>Reservation details</h3>

            <div>{reservation.reservation_at_tzd.format('ddd, MMMM D')}</div>
            <div>{reservation.reservation_at_tzd.format('h:mm a')}</div>
            <div>{reservation.party_size} guests</div>
            <div>{reservation.table_type}</div>
          </div>

          <div className="section">
            <h3>Your details</h3>

            <div>
              {reservation.user.first_name} {reservation.user.last_name}
            </div>
            <div>{reservation.user.email}</div>

            <div className={styles.edit}>
              <Link to={'../info'} className="lightButton">
                Edit your details
              </Link>
            </div>
          </div>
        </div>

        {methods.length > 0 && (
          <div className="section">
            <h3>Choose an existing payment method</h3>

            <PaymentPicker reservation={reservation} />
          </div>
        )}

        <div className="section">
          <h3>Enter your credit card</h3>

          {reservation.restaurant.type === 'event' ? null : (
            <p>
              You will be charged upon booking. Tax, tip and any overages are
              not included.
            </p>
          )}

          <Elements stripe={stripe} options={stripe_options}>
            <CheckoutForm reservation={reservation} />
          </Elements>
        </div>
      </div>
    </>
  );
};

interface CheckoutProps {
  reservation: GetReservation;
}

const CheckoutForm: React.FC<CheckoutProps> = ({ reservation }) => {
  const auth = useAtomValue(AuthAtom);
  const reloadPaymentMethods = useSetAtom(reloadPaymentMethodsAtom);
  const [directLink, updateDirectLink] = useDirectLinkState();
  const [booking, setBooking] = useState<boolean>(false);
  const [name, setName] = useState<string>(
    reservation
      ? `${reservation.user.first_name} ${reservation.user.last_name}`
      : ''
  );
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    directLink?.payment_error
  );

  useEffect(() => {
    setName(`${reservation.user.first_name} ${reservation.user.last_name}`);
  }, [reservation.user.first_name, reservation.user.last_name]);

  const onName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };

  const onSubmit = (event: React.SyntheticEvent) => {
    // es-lint is finicky about promises and event handlers
    event.preventDefault();
    void handleSubmit();
  };

  const onClick = (event: React.SyntheticEvent) => {
    // es-lint is finicky about promises and event handlers
    event.preventDefault();
    void handleSubmit();
  };

  const handleSubmit = async () => {
    setBooking(true);

    setErrorMessage(undefined);

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Trigger form validation and wallet collection
    const { error } = await elements.submit();

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setBooking(false);
      return;
    }

    try {
      const response = await setupIntent(auth!.token);

      if (!response?.setupIntent) {
        Sentry.captureMessage(
          'DirectLink: Problem occurred with api/setup-intent'
        );
        setErrorMessage(
          'Sorry, there was an issue.  Please try again in a moment.'
        );
        setBooking(false);
        return;
      }

      const { setupIntent: result, error } = await stripe.confirmSetup({
        elements,
        clientSecret: response.setupIntent.client_secret,
        confirmParams: {
          return_url: `${Config.data.uri.app}/reservation/${reservation.uuid}/confirm`, // FIXME: this won't necessarily work because we don't have a page to accept these params - but we don't have payment types that should require this
          payment_method_data: {
            billing_details: {
              name,
              email: reservation.user.email,
            },
          },
        },
        redirect: 'if_required',
      });

      if (error) {
        setErrorMessage(error.message);
        setBooking(false);
        return;
      }

      const payment_method = result?.payment_method as string;
      const dorsia_payment = await setPaymentMethod(auth!.token, {
        paymentMethodId: payment_method,
      });

      updateDirectLink({
        payment_method: dorsia_payment.payment_id,
        claim_body: {
          payment_method_id: dorsia_payment.payment_id,
        },
      });

      setBooking(false);
      reloadPaymentMethods();

      if (reservation?.restaurant.type === 'event') {
        navigate('../confirm');
      } else {
        navigate(`../101`);
      }
    } catch (e: unknown) {
      Sentry.captureException(e);
      setErrorMessage(
        'Sorry, there was an issue.  Please try again in a moment.'
      );
      setBooking(false);
    }
  };

  const payment_options: StripePaymentElementOptions = {
    defaultValues: {
      billingDetails: {
        name: `${reservation.user.first_name} ${reservation.user.last_name}`,
        email: reservation.user.email,
        phone: reservation.user.phone,
      },
    },
    fields: {
      billingDetails: {
        name: 'never',
      },
    },
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        {errorMessage && <div className={styles.error}>{errorMessage}</div>}

        <div className={styles.field}>
          <label>Cardholder name</label>
          <input type="text" value={name} onChange={onName} />
        </div>

        <PaymentElement options={payment_options} />
      </form>

      <button
        className="nextButton"
        onClick={onClick}
        disabled={!stripe || booking}
      >
        Next
      </button>

      <RemainingTime />
    </>
  );
};

export default Payment;
