import { useAtomValue } from 'jotai';
import Header from '../Header/Header.tsx';
import { ReservationAtom } from '../lib/state.ts';
import UserLight from '@/assets/icons/User_light.svg?react';
import UserAdd from '@/assets/icons/User_add.svg?react';
import styles from './Guest.module.css';
import Config from '@/lib/Config.ts';
import { Link } from '../Confirmation/Link.tsx';
import Share from '@/assets/icons/Share.svg?react';

interface PersonData {
  isConfirmed: boolean;
  name: string;
}

export function Person({ name }: PersonData) {
  return (
    <div className={styles.iconWrapper}>
      <div className={styles.iconInit}>
        <UserLight className={styles.userIcon} />
        <div className={styles.iconText}>{name}</div>
      </div>
    </div>
  );
}

function GuestList({ guests }: { guests: string[] }) {
  return (
    <>
      {guests.map((_g, index) => {
        const guest = index < guests.length ? guests[index] : null;

        // @ts-ignore
        const guestName = `${guest?.first_name} ${guest?.last_name} `;
        return (
          <Person
            key={index}
            isConfirmed={!!guest}
            // @ts-ignore
            name={guestName}
          />
        );
      })}
    </>
  );
}

export function Guest() {
  const reservation = useAtomValue(ReservationAtom);

  if (!reservation) {
    return;
  }

  const guests = reservation?.guests;
  const dorsiaweb = Config.data.uri.web;
  const shareurl = `${dorsiaweb}reservation/${reservation.uuid}/guest`;

  const shareDetails = {
    url: shareurl,
    title: `You have been invited to ${
      reservation.restaurant.name
    } on ${reservation.reservation_at_tzd.format('ddd, MMMM D')}`,
    text: `You have been invited to ${
      reservation.restaurant.name
    } on ${reservation.reservation_at_tzd.format('ddd, MMMM D')}`,
  };

  const handleSharing = async () => {
    if (navigator.share) {
      try {
        await navigator
          .share(shareDetails)
          .then(() => console.log('Hooray! Your content was shared'));
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      console.log(
        'Web share is currently not supported on this browser. Please provide a callback'
      );
    }
  };

  const invitesRemaining = Array.from(
    { length: reservation.party_size - guests.length - 1 },
    (_, i) => '' + (i + 1)
  );

  return (
    <>
      <Header
        title="Invite Guests"
        step={0}
        isEvent={true}
        show_image={false}
      />
      <div className={styles.guestWrapper}>
        <div className={styles.description}>
          Up to {reservation.party_size} guests including you
        </div>
        <div className={styles.iconWrapper} onClick={handleSharing}>
          <div className={styles.iconInit}>
            <UserLight className={styles.userIcon} />
            <div className={styles.iconText}>
              {reservation.user.first_name} {reservation.user.last_name}
            </div>
          </div>
          <div className={styles.hostLabel}>Host</div>
        </div>
        {guests ? <GuestList guests={guests} /> : null}
        {invitesRemaining.map((index) => (
          <div
            key={index}
            className={styles.iconWrapper}
            onClick={handleSharing}
          >
            <div className={styles.iconInit}>
              <UserAdd className={styles.userAddIcon} />
              <div className={styles.iconTextLight}>Invite Guest</div>
            </div>
            <Share className={styles.shareIcon} />
          </div>
        ))}
        <div className={styles.spacer}></div>
        <Link link={shareurl} />
      </div>
    </>
  );
}
