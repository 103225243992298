import {isPossiblePhoneNumber, parsePhoneNumber, PhoneNumber} from "libphonenumber-js";
import {Country} from "react-phone-number-input";

export const getPhone = (number: string | undefined, defaultCountry: Country | undefined = 'US'): PhoneNumber | null => {
    if (!number) {
        return null;
    }

    if (number === '') {
        return null;
    }

    const country = defaultCountry ?? 'US';

    if (!isPossiblePhoneNumber(number, country)) {
        return null;
    }

    return parsePhoneNumber(number, country);
}