import clsx from "clsx";
import {useAtomValue} from "jotai/index";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {FrictionlessStateAtom, useUpdateTip} from "../lib/state.ts";
import { useDebouncedCallback } from 'use-debounce';

import Close from "../../../assets/images/close.svg?react"
import Delete from "../../../assets/images/delete.svg?react"

import styles from './CustomTip.module.css';

const DIGITS = Array.from({length: 9}, (_, i) => '' + (i + 1));
const MAX = 999999999.99;

const CustomTip: React.FC = () => {
    const state = useAtomValue(FrictionlessStateAtom);
    const navigate = useNavigate();
    const {custom, updateTip} = useUpdateTip();
    const [number, updateNumber] = useState<string>(custom ? '' + custom : '0');
    const setNumber = useDebouncedCallback((number: string) => {
        if (parseFloat(number) > MAX) {
            number = '' + MAX;
        }

        updateNumber(number);
    }, 50);

    const onNext = () => {
        void updateTip(parseFloat(number), 'custom');
        navigate('../details');
    };

    const onClick = (digit: string) => {
        switch(digit) {
            case '.':
                if (!number.includes('.')) {
                    setNumber('' + number + '.');
                }
                break;

            case '<=':
                if (number.length === 1 || number === "0." || /0\.\d$/.test(number)) {
                    setNumber('0');
                } else if (/\.\d$/.test(number)) { // backspacing over ".9" gets rid of period as well
                    setNumber(number.substring(0, number.length - 2));
                } else if (number.length > 1) {
                    setNumber(number.substring(0, number.length - 1));
                }
                break;

            default:
                if (number === '0') {
                    setNumber(digit);
                } else if (/\.\d{2,}$/.test(number)) { // just change the last digit if we have ".99"
                    setNumber(number.substring(0, number.length - 1) + digit);
                } else {
                    setNumber(number + digit);
                }
                break;
        }
    }

    const CurrencyFormatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: state.currency
    });

    return (
        <div className={styles.section}>
            <Link to="../details"><Close /></Link>
            <h3>Please input a custom tip amount</h3>
            <div className={styles.amount}>{CurrencyFormatter.format(parseFloat(number))}</div>

            <div className={styles.grid}>
                {DIGITS.map(digit => <Digit key={digit} digit={digit} onClick={onClick} />)}
                <Digit digit={'.'} onClick={onClick} />
                <Digit digit={'0'} onClick={onClick} />
                <Digit digit={'<='} onClick={onClick} />
            </div>

            <button className="nextButton" onClick={onNext}>Confirm</button>
        </div>
    )
}


interface DigitProps {
    digit: string
    onClick: (digit: string) => void
}

const Digit: React.FC<DigitProps> = ({digit, onClick}) => {
    const [hover, setHover] = useState<boolean>(false);
    const [down, setDown] = useState<boolean>(false);
    const [touch, setTouch] = useState<boolean>(false);

    const props = {
        className:    clsx({[styles.hover]: hover, [styles.down]: down, [styles.touch]: touch}),
        onMouseOver:  () => setHover(true),
        onMouseOut:   () => setHover(false),
        onMouseDown:  () => setDown(true),
        onMouseUp:    () => setDown(false),
        onTouchStart: () => setTouch(true),
        onTouchEnd:   () => setTouch(false),
        onClick:      () => onClick(digit)
    }

    if (digit === '<=') {
        return <button {...props}><Delete /></button>
    }

    return <button {...props}>{digit}</button>
}

export default CustomTip;