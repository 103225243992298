import clsx from 'clsx';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { LeftArrow, RightArrow } from '../ListArrow/ListArrow.tsx';

import styles from './Scrollable.module.css';

interface Props {
  children: React.ReactNode;
}

export const Scrollable: React.FC<PropsWithChildren<Props>> = ({
  children,
}) => {
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(1);
  const [show_left, setShowLeft] = useState(false);
  const [show_right, setShowRight] = useState(false);
  const [offsets, setOffsets] = useState<number[]>([]);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      let selected_page = 0;
      const page_offsets: number[] = [0];
      const view_width = ref.current.offsetWidth;

      let page_width = 0;
      let offset = 0;
      for (const child of ref.current.childNodes) {
        const typed = child as HTMLDivElement;
        const style = window.getComputedStyle(typed);
        const margin_right: number = parseFloat(
          style.marginRight.replace('px', '')
        );
        const width = typed.offsetWidth + margin_right;
        offset += width;
        if (page_width + width <= view_width) {
          page_width += width;
        } else {
          page_offsets.push(offset - width);
          page_width = width;
        }

        if (typed.className.includes('selected')) {
          selected_page = page_offsets.length - 1;
        }
      }

      setOffsets(page_offsets);
      setPages(page_offsets.length - 1);

      if (selected_page > 0) {
        setPage(selected_page);
        setShowLeft(selected_page > 0);
        setShowRight(pages !== 0 && selected_page < pages);
      }
    }
  }, [ref.current, children]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({ left: offsets[page], behavior: 'smooth' });
    }

    setShowLeft(page > 0);
    setShowRight(pages !== 0 && page < pages);
  }, [page, pages]);

  const onLeft = () => setPage(Math.max(page - 1, 0));
  const onRight = () => setPage(Math.min(page + 1, pages));

  return (
    <div className={styles.scrolling_container}>
      <div className={styles.items}>{children}</div>
    </div>
  );

  /*
    return (
        <div className={styles.scrolling_container}>
            <div className={styles.items} ref={ref}>
                {children}
            </div>
            <LeftArrow onClick={onLeft} className={clsx(styles.leftArrow, {[styles.hide]: !show_left})} />
            <RightArrow onClick={onRight} className={clsx(styles.rightArrow, {[styles.hide]: !show_right})} />
        </div>
    )
        */
};
