import * as Sentry from "@sentry/react";
import {useAtom} from "jotai";
import React, {useState} from "react";
import PhoneInputWithCountrySelect, {Country} from "react-phone-number-input";
import {useNavigate} from "react-router-dom";
import {getPhone} from "../../../lib/getPhone.ts";
import Dorsia from "../../../assets/images/Dorsia.svg?react"
import {verify} from "../../../lib/api.ts";
import {PhoneAtom, useFrictionlessState} from "../lib/state.ts";

import styles from './Phone.module.css';

const Phone: React.FC = () => {
    const navigate = useNavigate();
    const [directLink] = useFrictionlessState();
    const [phone, setPhone] = useAtom(PhoneAtom);
    const [number, setNumber] = useState<string>(phone?.formatNational() ?? '');
    const [country, setCountry] = useState<Country>('US');

    const onSubmit = (event: React.SyntheticEvent) => {  // es-lint is finicky about promises and event handlers
        event.preventDefault();
        void onNext();
    }

    const onNext = async () => {
        try {
            const parsed = getPhone(number, country);
            if (parsed) {
                setPhone(parsed);
                await verify(parsed.formatInternational()!);
                navigate('../auth');
            }
        } catch (e: unknown) {
            console.error(e);
            Sentry.captureException(e);
        }
    }

    const onPhoneCountryChange = (country: Country) => {
        setCountry(country);
    }

    const onPhoneNumberChange = (number: string) => {
        setNumber(number);
    }

    return (
        <div className={styles.section}>
            <Dorsia className={styles.dorsia} />

            <hr />

            <div className="content">

                {directLink.unauthorized_error && <h3>Please provide the phone number from which this reservation was created.</h3>}

                <form className="section" onSubmit={onSubmit}>
                    <div className={styles.field}>
                        <label>Phone number</label>
                        <PhoneInputWithCountrySelect
                            placeholder="Enter phone number"
                            value={number}
                            country={country ?? 'US'}
                            defaultCountry="US"
                            onChange={onPhoneNumberChange}
                            onCountryChange={onPhoneCountryChange}
                        />
                    </div>

                    <input type="submit" className="nextButton" disabled={number === ''} value={`Next`} />
                </form>
            </div>
        </div>
    )
}

export default Phone
