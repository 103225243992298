import * as Sentry from "@sentry/react";
import {useAtom} from "jotai";
import React, {Suspense, useEffect} from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import RootLoader from "../../components/RootLoader/RootLoader.tsx";
import Config from "../../lib/Config.ts";
import {useInit, useResetState, AuthAtom, useReservation, usePaymentMethods} from "./lib/state.ts";

const DirectLink: React.FC = () => {
    useInit();
    useReservation();
    usePaymentMethods();

    const {uuid} = useParams();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const resetState = useResetState();
    const [auth, setAuth] = useAtom(AuthAtom);

    useEffect(() => {
        // Scroll to the top of the page any time the path changes
        document.getElementById('root')!.scrollIntoView();
    }, [pathname]);

    const onLogout = () => {
        Sentry.setUser(null);
        setAuth(null);
        navigate(`/reservation/${uuid}`);
    }

    const onClear = () => {
        resetState();
        navigate(`/reservation/${uuid}`);
    }

    return (
        <>
            <div className="container">
                <Suspense fallback={<RootLoader />}>
                    <Outlet />
                </Suspense>
            </div>

            {Config.data.environment !== 'production' && (
                <div className="resetArea">
                    <button onClick={onClear} className="resetButton">Start Over</button>
                    {!!auth && <button onClick={onLogout} className="resetButton">Log Out</button>}
                </div>
            )}

            <div className="build">{Config.data.build}</div>
        </>
    )
}

export default DirectLink
