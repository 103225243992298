import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { getMapUrl } from '../../../lib/getMapUrl.ts';
import Header from '../Header/Header.tsx';
import { EventReservationAtom, useRestaurantId } from '../lib/state.ts';
import styles from '../Confirmation/Confirmation.module.css';
import DownloadDorsia from '@/components/DownloadDorsia/DownloadDorsia.tsx';

function EventConfirmation() {
  const id = useRestaurantId();
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
  const reservation = useAtomValue(EventReservationAtom);
  const width = isMobile ? 800 : 258;
  const height = isMobile ? 427 : 263;
  const map_url = getMapUrl(
    width,
    height,
    reservation?.restaurant ?? undefined,
    isRetina
  );

  const navigate = useNavigate();

  const onRestart = () => {
    navigate('../booking');
  };

  if (!reservation.event) {
    return (
      <div>
        <h2>Please check your email for reservation details.</h2>
        <div onClick={onRestart}>Go back</div>
      </div>
    );
  }

  return (
    <>
      <Header title="Booking confirmed" step={0} />

      <div className="content">
        <div className={styles.grid}>
          <div>
            <h2>{reservation.event.name}</h2>

            <div className="section">
              <div>
                {reservation.event.datetime_local__formatted.date_dow_short}
                {', '}
                {reservation.event.datetime_local__formatted.date_long}
              </div>
              <div>{reservation.event.datetime_local__formatted.time}</div>
              <div>{reservation.party_size} guests</div>
              <div>{reservation.total_paid__currency}</div>
            </div>

            {reservation.user ? (
              <div className="section">
                <div>
                  {reservation.user.first_name} {reservation.user.last_name}
                </div>
                <div>{!!user_phone && user_phone.formatNational()}</div>
                <div>{reservation.user.email}</div>
              </div>
            ) : null}
          </div>

          {reservation.restaurant.address ? (
            <div className={styles.mapContainer}>
              {map_url && reservation?.restaurant.address && (
                <img
                  className={styles.map}
                  src={map_url.toString()}
                  alt="Map"
                />
              )}
            </div>
          ) : null}
        </div>

        {!isMobile && <hr />}

        <div className={clsx('section', styles.info)}>
          <div className={styles.content}>
            <div>{reservation.event.name}</div>
          </div>
        </div>

        <hr />

        <DownloadDorsia reservation={id} />

        <hr />

        <div className="section">
          <button className="nextButton" onClick={onRestart}>
            Create a New Reservation
          </button>
        </div>
      </div>
    </>
  );
}

export default EventConfirmation;
