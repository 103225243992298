import UserLight from '@/assets/icons/User_light.svg?react';
import UserAdd from '@/assets/icons/User_add.svg?react';
import styles from './Confirmation.module.css';
import Share from '@/assets/icons/Share.svg?react';
import { GetReservation } from '@/lib/types';
import Config from '@/lib/Config.ts';
import { useNavigate } from 'react-router-dom';

interface PersonData {
  isConfirmed: boolean;
  name: string;
}

interface GuestsData {
  guests: string[];
  reservation: GetReservation;
}

export function Person({ name }: PersonData) {
  return (
    <div className={styles.iconWrapper}>
      <UserLight className={styles.userIcon} />
      <div className={styles.iconText}>{name}</div>
    </div>
  );
}

export function Guests({ guests, reservation }: GuestsData) {
  const navigate = useNavigate();

  const dorsiaweb = Config.data.uri.web;
  const shareurl = `${dorsiaweb}reservation/${reservation.uuid}/guest`;

  const shareDetails = {
    url: shareurl,
    title: `You have been invited to ${
      reservation.restaurant.name
    } on ${reservation.reservation_at_tzd.format('ddd, MMMM D')}`,
    text: `You have been invited to ${
      reservation.restaurant.name
    } on ${reservation.reservation_at_tzd.format('ddd, MMMM D')}`,
  };

  const handleSharing = async () => {
    if (navigator.share) {
      try {
        await navigator
          .share(shareDetails)
          .then(() => console.log('Hooray! Your content was shared'));
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      console.log(
        'Web share is currently not supported on this browser. Please provide a callback'
      );
    }
  };

  function guestView() {
    navigate('../guests');
  }

  return (
    <div className={styles.guests}>
      <div className={styles.guestHeader}>
        <div>Invite your guests</div>
        <div onClick={handleSharing}>
          <Share className={styles.shareIcon} />
        </div>
      </div>
      <div className={styles.guestList}>
        <div className={styles.iconWrapper}>
          <UserLight className={styles.userIcon} />
          <div className={styles.iconText}>You</div>
        </div>

        {guests.map((_g, index) => {
          const guest = index < guests.length ? guests[index] : null;

          return (
            <Person
              key={index}
              isConfirmed={!!guest}
              // @ts-ignore
              name={guest?.first_name || ''}
            />
          );
        })}
        <div className={styles.iconWrapper} onClick={guestView}>
          <UserAdd className={styles.userAddIcon} />
          <div className={styles.iconTextLight}>Guest</div>
        </div>
      </div>
    </div>
  );
}
