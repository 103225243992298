import React, { Suspense } from 'react';
import HeaderImage, { HeaderImageLoader } from './HeaderImage/HeaderImage.tsx';
import styles from './Header.module.css';
import Dorsia from '@/assets/images/Dorsia.svg?react';

interface Props {
  title: string;
  subtitle?: string;
  step: number;
  show_image?: boolean;
  isEvent?: boolean;
}

const Header: React.FC<Props> = ({
  title,
  step,
  show_image = true,
  isEvent = false,
  subtitle,
}) => {
  return (
    <>
      <div className={styles.header}>
        {!isEvent && <span className={styles.booking}>Booking by</span>}
        <Dorsia className={styles.dorsia} />
        <hr />
        <div className={styles.title}>
          <div className={styles.titleWrapper}>
            <h1>{title}</h1>
            {subtitle ? (
              <div className={styles.subtitle}>{subtitle}</div>
            ) : null}
          </div>
          <div>
            {step > 0 && !isEvent ? (
              <div className={styles.info}>Step {step} of 5</div>
            ) : null}
          </div>
        </div>
      </div>

      {show_image && (
        <Suspense fallback={<HeaderImageLoader />}>
          <HeaderImage />
        </Suspense>
      )}
    </>
  );
};

export default Header;
