import { WidgetState } from './types.ts';

export const isBookingReady = (reservation: WidgetState) => {
  return (
    !!reservation.date && !!reservation.availability && !!reservation.option
  );
};

export const isInfoReady = (reservation: WidgetState) => {
  return (
    isBookingReady(reservation) &&
    !!reservation.first_name &&
    !!reservation.last_name &&
    !!reservation.phone_country &&
    !!reservation.phone_number &&
    !!reservation.email
  );
};

export const isEventInfoReady = (reservation: WidgetState) => {
  return (
    !!reservation.first_name &&
    !!reservation.last_name &&
    !!reservation.phone_number &&
    !!reservation.email
  );
};

export const isPaymentReady = (reservation: WidgetState) => {
  return !!reservation.payment_method;
};
