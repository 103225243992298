import {useAtomValue} from "jotai";
import React from "react";
import Header from "../Header/Header.tsx";
import {CancellationAtom} from "../lib/state.ts";

const Canceled: React.FC = () => {
    const cancellation = useAtomValue(CancellationAtom);

    if (!cancellation) {
        return <Header title="Your reservation has already been canceled." />;
    }

    return (
        <>
            <Header title="Your reservation has been canceled." />

            {/*<div className="content">*/}
            {/* TODO: Wait for this to be done on the API */}
            {/*    {*/}
            {/*        !cancellation.short_cancellation && (*/}
            {/*            <p className={styles.text}>Your card on file will be refunded {cancellation.total_after_cancellation}. Please allow 3-5 days for processing.</p>*/}
            {/*        )*/}
            {/*    }*/}
            {/*</div>*/}

        </>
    )
}

export default Canceled
