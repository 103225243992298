import dayjs from "dayjs";
import {atom, useAtom, useAtomValue, useSetAtom} from "jotai";
import {atomWithStorage} from "jotai/utils";
import {useEffect} from "react";
import usePromise from "react-promise-suspense";
import {useParams} from "react-router-dom";
import {getRestaurant} from "../../../lib/api.ts";
import {computeAvailability} from "../../../lib/computeAvailability.ts";
import {
    RestaurantResponseData,
    RestaurantResponseMeta
} from "../../../lib/types.ts";
import {TEMP_STATE_VERSION, TempState, UrlState} from "./types.ts";

export const RestaurantIdAtom = atom<number | null>(null);
export const RestaurantAtom = atom<RestaurantResponseData | null>(null);
export const RestaurantMetaAtom = atom<RestaurantResponseMeta | null>(null);
export const UrlStateAtom = atom<UrlState>({});


export const BookingUrlStateAtom = atomWithStorage<TempState>('booking_reservation', {
    version: TEMP_STATE_VERSION
});

export const updateBookingUrlStateAtom = atom(
    (get) => get(BookingUrlStateAtom),
    (get, set, update: Partial<TempState>) => {
        set(BookingUrlStateAtom, {
            ...get(BookingUrlStateAtom),
            ...update
        });
    }
);

export const resetBookingUrlStateAtom = atom(null, (_get, set) => {
    set(BookingUrlStateAtom, {
        version: TEMP_STATE_VERSION
    });
});

export const useRestaurantId = () => {
    const {id} = useParams();
    const [restaurant_id, setRestaurantId] = useAtom(RestaurantIdAtom);

    useEffect(() => {
        if (id) {
            const parsed = parseInt(id, 10);
            if (parsed != restaurant_id) {
                setRestaurantId(parsed);
            }
        }
    }, [id]);

    return restaurant_id;
}

// Updating this key will force an API hit to load the latest restaurant availability
let RELOAD_RESTAURANT = dayjs();
let RELOAD_RESTAURANT_KEY = RELOAD_RESTAURANT.format();

export const reloadResaurantKey = () => {
    RELOAD_RESTAURANT = dayjs();
    RELOAD_RESTAURANT_KEY = RELOAD_RESTAURANT.format();
}

export const useResetState = (): () => void => {
    const resetReservation = useSetAtom(resetBookingUrlStateAtom);

    return () => {
        reloadResaurantKey();
        resetReservation();
    };
}

export const useRestaurant = () => {
    const id = useRestaurantId();
    const state = useAtomValue(updateBookingUrlStateAtom);
    const [restaurant, setRestaurant] = useAtom(RestaurantAtom);
    const [meta, setMeta] = useAtom(RestaurantMetaAtom);

    let og_date = RELOAD_RESTAURANT.format('YYYY-MM-DD');
    let date = RELOAD_RESTAURANT;
    let time = "0:00";

    if (state.date) {
        og_date = state.date;
        date    = dayjs(og_date);
    }

    if (date.isToday()) {
        time = RELOAD_RESTAURANT.format('H:mm');
    } else if (restaurant?.operating_hours !== undefined && restaurant.operating_hours.length > 0) {
        const day = date.day();

        if (restaurant.operating_hours[day]?.length) {
            time = restaurant.operating_hours[day][0].start;
        }
    }

    const response = usePromise(getRestaurant, [id, og_date, time, RELOAD_RESTAURANT_KEY]);

    useEffect(() => {
        if (response?.data) {
            if (response.data.id != restaurant?.id) {
                setRestaurant(response.data);
            }
        }

        if (response?.meta) {
            if (response.meta.date !== meta?.date || response.meta.availability[0]?.uid !== meta?.availability[0]?.uid) {
                if (response.meta.availability) {
                    setMeta(computeAvailability(response.meta));
                } else {
                    setMeta(response.meta);
                }
            }
        }
    }, [response?.meta.date, response?.meta.time]);

    return {
        restaurant,
        meta
    }
}