import React from "react";
import styles from './Header.module.css';
import Dorsia from '../../../assets/images/Dorsia.svg?react';

interface Props {
    title: string
}

const Header: React.FC<Props> = ({title}) => {
    return (
        <div className={styles.header}>
            <Dorsia className={styles.dorsia} />

            <hr />

            <div className={styles.title}>
                <h1>{title}</h1>
            </div>
        </div>
    );
}

export default Header