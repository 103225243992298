import React from "react";
import AmexIcon from "../../assets/cards/AmexIcon.svg?react"
import ApplePayIcon from "../../assets/cards/ApplePayIcon.svg?react"
import CreditCardIcon from "../../assets/cards/CreditCardIcon.svg?react"
import DinersClubMonoIcon from "../../assets/cards/DinersClubMonoIcon.svg?react"
import DiscoverIcon from "../../assets/cards/DiscoverIcon.svg?react"
import MastercardIcon from "../../assets/cards/MastercardIcon.svg?react"
import VisaIcon from "../../assets/cards/VisaIcon.svg?react"

export type CardNames = 'amex'
               | 'applepay'
               | 'diners'
               | 'discover'
               | 'mastercard'
               | 'visa';

interface Props {
    name: CardNames,
    className?: string
}

const Card: React.FC<Props> = ({name, className}) => {
    switch(name) {
        case 'amex':       return <AmexIcon className={className} />;
        case 'applepay':   return <ApplePayIcon className={className} />;
        case 'diners':     return <DinersClubMonoIcon className={className} />;
        case 'discover':   return <DiscoverIcon className={className} />;
        case 'mastercard': return <MastercardIcon className={className} />;
        case 'visa':       return <VisaIcon className={className} />;
        default:           return <CreditCardIcon className={className} />
    }
}

export default Card
