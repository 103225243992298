import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header/Header.tsx';
import Config from '../../../lib/Config.ts';

import styles from './Index.module.css';

const Index: React.FC = () => {
  return (
    <div className="container">
      <PerEnv />
    </div>
  );
};

const PerEnv: React.FC = () => {
  switch (Config.data.environment) {
    case 'local':
      return (
        <>
          <Header title="Local Test" step={0} show_image={false} />
          <div className={clsx('content', styles.index)}>
            <Link to="./172">Principe</Link>
            <Link to="./317">Event</Link>
            <Link to="./322">HWood</Link>
            <Link to="./336">Black Coffee</Link>
          </div>
        </>
      );

    case 'staging':
      return (
        <>
          <Header title="Staging Test" step={0} show_image={false} />
          <div className={clsx('content', styles.index)}>
            <Link to="./172">Principe</Link>
            <Link to="./314">Event</Link>
            <Link to="./322">HWood</Link>
            <Link to="./336">Black Coffee</Link>
          </div>
        </>
      );

    default:
    case 'production':
      return (
        <>
          <Header title="" step={0} show_image={false} />
          <div className={clsx('content', styles.index)}>
            <Link to="./172">Principe</Link>

            <Link to="./314">Event</Link>
            <div>dorsia.com</div>
          </div>
        </>
      );
  }
};

export default Index;
