import { Dayjs } from 'dayjs';
import { Country } from 'react-phone-number-input';
import {
  AvailabilityOption,
  MetaAvailability,
  Post_Confirm,
  Post_Reserve,
  RestaurantResponseData,
} from '../../../lib/types.ts';

export const WIDGET_STATE_VERSION = '20230926.1727';

export interface WidgetState {
  version: string;
  expires: Dayjs;
  date?: string;
  availability?: MetaAvailability;
  option?: AvailabilityOption;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_country?: Country;
  phone_number?: string;
  phone_confirmed: boolean | null;
  policy_accepted: boolean | null;
  payment_method?: string;
  confirm_body?: Post_Confirm;
  reserve_body?: Post_Reserve;
  update_user_error?: string;
  payment_error?: string;
  reservation_error?: string;
  opt_in_accepted?: number;
  event?: Event;
  eventInfo?: EventInfo;
}

export interface CardImage {
  id: number;
  original_url: string;
}

export interface EventInfo {
  id: number;
  ticektItemId: number;
  fee_restaurant?: number;
  fee_restaurant__currency?: string;
  items?: Items[];
  party_size?: number;
  restaurant?: RestaurantResponseData;
  service_charges?: [];
  service_charges__currency?: [];
  tax_amount?: number;
  tax_amount__currency?: string;
  tax_rate?: number;
  total_paid?: number;
  total_paid__currency?: string;
}

export interface Items {
  guests?: number;
  price__currency?: string;
  quantity?: number;
  item?: ItemInfo;
}

export interface ItemInfo {
  name?: string;
  image?: string;
}

export interface Event {
  id: number;
  card_image?: CardImage;
  date?: string;
  datetime_local__formatted?: string;
  name?: string;
  time?: string;
  time_end?: string;
}

export interface UserDetails {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
}
