import React from "react";
import Header from "../Header/Header.tsx";

import styles from './NotFound.module.css';

const NotFound: React.FC = () => {
    return (
        <>
            <Header title="Invalid Reservation Link." />

            <div className="content">
                <p className={styles.text}>This link could not open the reservation.  Please contact your reservationist for additional assitance.</p>
            </div>

        </>
    )
}

export default NotFound
