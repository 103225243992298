import {Dayjs} from "dayjs";

export const FRICTIONLESS_STATE_VERSION = '20231101.1614';

export type Currency = 'USD' | 'GBP';

export interface FrictionlessState {
    version: string
    expires: Dayjs
    currency: Currency
    uuid?: string
    unauthorized_error: null | true
}