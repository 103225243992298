import Config from './Config.ts';
import { MappableRestaurant } from './types.ts';

export const getMapUrl = (
  width: number,
  height: number,
  restaurant?: MappableRestaurant,
  twoX = false
) => {
  if (!restaurant) {
    return null;
  }

  const username = Config.data.mapbox.username;
  const style_id = Config.data.mapbox.style_id;
  const lon = restaurant.longitude;
  const lat = restaurant.latitude;
  const zoom = 15;
  const bearing = 0;
  const _2x = twoX ? '@2x' : '';

  // https://docs.mapbox.com/api/maps/static-images/
  const url = new URL(
    `https://api.mapbox.com/styles/v1/${username}/${style_id}/static/pin-l-embassy+000(${lon},${lat})/${lon},${lat},${zoom},${bearing}/${width}x${height}${_2x}`
  );
  url.searchParams.append('access_token', Config.data.mapbox.access_token);

  return url;
};
