import {Country} from "react-phone-number-input";
import {AvailabilityOption, MetaAvailability} from "../../../lib/types.ts";

export const TEMP_STATE_VERSION = '20231004.1554';

export interface TempState {
    version: string;
    date?: string;
    availability?: MetaAvailability;
    option?: AvailabilityOption;
    phone_country?: Country;
    phone_number?: string;
}

export interface UrlState {
    url?: string;
    state?: TempState;
}