import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import {useAtomValue, useSetAtom} from 'jotai';
import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Card, {CardNames} from '../../../components/Card/Card.tsx';
import {
    PaymentMethodsByIdAtom,
    usePaymentMethods,
    useRestaurantId,
} from '../lib/state.ts';
import Header from '../Header/Header.tsx';
import {getPhone} from '@/lib/getPhone.ts';
import {createEventReservationBooking, getEventReservation} from '@/lib/api.ts';
import {ApiError} from '@/lib/ApiError.ts';
import {
    loadStripe,
    Stripe,
} from '@stripe/stripe-js';
import {
    reloadRestaurantKey,
    EventReservationAtom,
    AuthAtom,
    useWidgetState,
    useResetState,
    useUserDetails,
} from '../lib/state.ts';
import styles from '../Confirm/Confirm.module.css';

import Config from "@/lib/Config.ts";

const EventConfirm: React.FC = () => {
    usePaymentMethods();
    const navigate = useNavigate();
    const [booking, setBooking] = useState<boolean>(false);
    // const [booking, setBooking] = useState<string>('');
    const setFinal = useSetAtom(EventReservationAtom);
    const auth = useAtomValue(AuthAtom);
    const [widget, updateWidget] = useWidgetState();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(
        widget?.payment_error
    );
    const user = useUserDetails();
    const resetState = useResetState();
    const methods_by_id = useAtomValue(PaymentMethodsByIdAtom);
    const id = useRestaurantId();
    const [stripe, setStripe] = useState<Stripe | null>(null);

    useEffect(() => {
        const waitForIt = async () => {
            setStripe(await loadStripe(Config.data.stripe.key));
        };
        void waitForIt();
    }, []);
    const onNext = () => {
        const go = async () => {
            if (auth && widget) {
                setBooking(true);

                const ticketItem = widget?.option?.seating_id;

                const confirm_body = {
                    items: [ticketItem],
                    confirmed_total: widget.eventInfo?.total_paid,
                    payment_method_id: payment_method.id,
                    skip_level_check: true,
                };

                try {
                    const response = await createEventReservationBooking(
                        auth.token,
                        confirm_body,
                        widget.event?.id,
                        id
                    );

                    if (response.data) {
                        setFinal(response.data);
                        resetState();
                        navigate(`../eventconfirmation`);
                    }
                } catch (e: unknown) {
                    if (
                        e instanceof ApiError &&
                        (e.type === 'ReserveErrorResponse' ||
                            e.type === 'CardErrorResponse')
                    ) {
                        updateWidget({
                            payment_error: e.message,
                        });

                        navigate(`../payment`);
                    } else if (
                        e instanceof ApiError &&
                        (
                            e.type === 'ActionRequiredResponse'
                        )
                    ) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        const result = await stripe?.confirmCardPayment(e.response.payment_intent_client_secret);

                        if (result?.error) {
                            setErrorMessage(result.error.message);

                            console.error(result?.error.message);
                            Sentry.captureException(result?.error);

                        } else {
                            const body = {
                                payment_id: result?.paymentIntent?.id, 
                                skip_level_check: true,
                            };

                            const response = await getEventReservation(auth.token, body, widget.event?.id, id);

                            if (response.data) {
                                setFinal(response.data);
                                resetState();
                                navigate(`../eventconfirmation`);
                            }                        }

                        // navigate(`../booking`);
                    } else if (
                        e instanceof ApiError &&
                        (
                            e.type === 'ReservationUnavailableErrorResponse' ||
                            e.type === 'ReservationResourceNotFound' ||
                            e.type === 'AlreadyCanceledReservationErrorResponse'
                        )
                    ) {
                        reloadRestaurantKey();
                        updateWidget({
                            reservation_error: e.message,
                        });

                        // navigate(`../booking`);
                    } else if (e instanceof Error) {
                        console.error(e.message);
                        Sentry.captureException(e);
                        navigate(`../booking`);
                    }
                } finally {
                    setBooking(false);
                }
            }
        };

        if (!widget.policy_accepted) {
            updateWidget({
                policy_accepted: false,
            });
            setBooking(false);
        } else {
            void go();
        }
    };

    const onMarketing = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.checked) {
            updateWidget({
                ...widget,
                opt_in_accepted: 1,
            });
        } else {
            updateWidget({
                ...widget,
                opt_in_accepted: 0,
            });
        }
    };

    const onAccept = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.checked) {
            updateWidget({
                policy_accepted: true,
            });
        } else {
            updateWidget({
                policy_accepted: null,
            });
        }
    };

    const payment_method = methods_by_id[widget.payment_method!];
    const user_phone = getPhone(widget.phone_number, widget.phone_country);

    function parseContent(content: string) {
        const split = content.split('\n');

        return (
            <>
                {split.map((line: string, i: number) => (
                    <div className={styles.policycontent} key={i}>
                        {line}
                    </div>
                ))}
            </>
        );
    }

    return (
        <>
            <Header title="Confirm your booking" step={4} show_image={false}/>

            {widget.policy_accepted === false && (
                <div className={clsx('content', styles.header_error)}>
                    You are missing required fields.
                </div>
            )}

            <div className="content">
                {widget?.event ? <h2>{widget?.event?.name}</h2> : null}
                <>
                    {widget.eventInfo?.restaurant?.show_merchandising_price_totals && (
                        <div className={clsx('section')}>
                            {widget.eventInfo?.restaurant?.show_merchandising_tax_and_tip ? (
                                <>
                                    <hr/>
                                    <div className={clsx('section', styles.payment)}>
                                        <div>Subtotal</div>
                                        <div className={styles.paymentnumber}>
                                            {widget.eventInfo?.fee_restaurant__currency}
                                        </div>
                                    </div>
                                    {widget.eventInfo?.tax_rate > 0 && (
                                        <div className={clsx('section', styles.payment)}>
                                            <div>Tax ({widget.eventInfo.tax_rate}%)</div>
                                            <div className={styles.paymentnumber}>
                                                {widget.eventInfo.tax_amount__currency}
                                            </div>
                                        </div>
                                    )}
                                    {widget.eventInfo.service_charges__currency?.map(
                                        (service, index) => (
                                            <div
                                                className={clsx('section', styles.payment)}
                                                key={index}
                                            >
                                                <div>{service.label}</div>
                                                <div className={styles.paymentnumber}>
                                                    {service.amount}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </>
                            ) : null}
                        </div>
                    )}
                </>
                {widget.eventInfo && (
                    <>
                        <div className={clsx('section', styles.payment_methods)}>
                            <div>
                                <h3>Total</h3>
                            </div>
                            <div>
                                <h3 className={styles.amount}>
                                    {widget.eventInfo.total_paid__currency}
                                </h3>
                            </div>
                        </div>
                        <div className={clsx('section', styles.payment_methods)}>
                            <div>
                                {!!payment_method && (
                                    <div className={styles.method}>Payment Method</div>
                                )}
                            </div>
                            <div>
                                {!!widget.eventInfo && (
                                    <h3 className={styles.amount}>
                                        {widget.eventInfo.total_amount__currency}
                                    </h3>
                                )}
                                {!!payment_method && (
                                    <div className={styles.card_info}>
                                        {payment_method.number}
                                        <Card name={payment_method.brand as CardNames}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
                <hr/>
                <div className={clsx('section', styles.policy)}>
                    {widget.eventInfo?.restaurant?.merchandising_policy_title ? (
                        <h3>{widget.eventInfo?.restaurant.merchandising_policy_title}</h3>
                    ) : (
                        <h3>Cancellation and modification policy</h3>
                    )}

                    <div className={styles.policywrapper}>
                        {widget.eventInfo?.restaurant?.merchandising_policy ? (
                            <>
                                {parseContent(
                                    widget.eventInfo?.restaurant?.merchandising_policy
                                )}
                            </>
                        ) : (
                            <>
                                <p>
                                    Reservations are bookable 30 days in advance and can be
                                    canceled up to 8 hours before the start time for a refund
                                    minus a 10% processing fee. Any cancellations made within 8
                                    hours of the reservation time are non-refundable. Once a
                                    reservation is booked, the minimum spend per person remains
                                    the same even if the party size decreases. Requests to
                                    increase a party size can be made to info@dorsia.com, but are
                                    not guaranteed.
                                </p>
                            </>
                        )}
                    </div>

                    {widget.eventInfo?.restaurant?.merchandising_marketing_opt_in_label ? (
                        <div className={styles.checkboxwrapper}>
                            <div className={styles.checkmarksection}>
                                <input id="marketing" type="checkbox" onChange={onMarketing}/>
                                <span className={styles.checkmark}></span>
                            </div>
                            <div>
                                <label htmlFor="marketing">
                                    {
                                        widget.eventInfo.restaurant
                                            .merchandising_marketing_opt_in_label
                                    }
                                </label>
                            </div>
                        </div>
                    ) : null}

                    <div className={styles.checkboxwrapper}>
                        <div className={styles.checkmarksection}>
                            <input id="understood" type="checkbox" onChange={onAccept}/>
                            <span className={styles.checkmark}></span>
                        </div>
                        {widget.eventInfo?.restaurant?.merchandising_policy_opt_in_label ? (
                            <div>
                                <label htmlFor="understood">
                                    {
                                        widget.eventInfo.restaurant
                                            .merchandising_policy_opt_in_label
                                    }
                                </label>
                            </div>
                        ) : (
                            <div>
                                <label htmlFor="understood">
                                    I understand and accept Dorsia's cancellation and modification
                                    policy and that my use of this service is subject to Dorsia's{' '}
                                    <a href="https://api.dorsia.com/terms" target="_blank">
                                        Terms of Use
                                    </a>{' '}
                                    and{' '}
                                    <a href="https://api.dorsia.com/privacy" target="_blank">
                                        Privacy Policy
                                    </a>
                                    .
                                </label>
                            </div>
                        )}
                    </div>

                    {widget.policy_accepted === false && (
                        <div className={styles.error}>
                            You must confirm that you understand Dorsia's cancellation and
                            modification policy
                        </div>
                    )}

                    <button className="nextButton" onClick={onNext} disabled={booking}>
                        {booking ? 'Booking...' : 'Complete Booking'}
                    </button>

                    {errorMessage && <div className={styles.error}>{errorMessage}</div>}

                </div>
                <div className={styles.grid}>
                    <div className="section">
                        <h3>Reservation details</h3>

                        {widget.eventInfo ? (
                            <>
                                <div>
                                    {' '}
                                    {widget.event?.datetime_local__formatted?.date_dow_long}
                                    {`, `}
                                    {widget.event?.datetime_local__formatted?.date_long}
                                </div>
                                <div>{widget.event?.datetime_local__formatted?.time}</div>
                                <div>{widget.eventInfo?.party_size} guests</div>
                                <div className={styles.minimum}>
                                    {widget.eventInfo.fee_restaurant__currency} total
                                </div>
                            </>
                        ) : (
                            <div>Loading...</div>
                        )}

                        <div className={styles.edit}>
                            <Link to={'../'} className="lightButton">
                                Edit booking
                            </Link>
                        </div>
                    </div>

                    <div className="section">
                        <h3>Your details</h3>

                        <div>
                            {user.first_name} {user.last_name}
                        </div>
                        <div>{!!user_phone && user_phone.formatNational()}</div>
                        <div>{user.email}</div>

                        <div className={styles.edit}>
                            <Link to={'../info'} className="lightButton">
                                Edit your details
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventConfirm;
