import { useAtomValue } from 'jotai';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header.tsx';
import RemainingTime from '../RemainingTime/RemainingTime.tsx';
import { ReservationAtom, useDirectLinkState } from '../lib/state.ts';
import Receipt from '../../../assets/101/receipt.svg?react';
import Comment from '../../../assets/101/comment_light.svg?react';
import CreditCard from '../../../assets/101/credit_card_light.svg?react';

import styles from './One01.module.css';

const One01: React.FC = () => {
  const reservation = useAtomValue(ReservationAtom);
  const navigate = useNavigate();
  const [, setDirectLink] = useDirectLinkState();

  const onUnderstand = () => {
    setDirectLink({
      rules_accepted: true,
    });
    navigate('../confirm');
  };

  if (!reservation) {
    return (
      <>
        <Header title="New to Dorsia?" step={3} show_image={false} />
        <div className="content"></div>
      </>
    );
  }

  return (
    <>
      <Header title="New to Dorsia?" step={3} show_image={false} />

      <div className="content">
        <div className={styles.info}>
          <h3>
            <Receipt /> Tax and tip are not included.
          </h3>
          <p>
            Remember, your prepaid minimum spend will be applied to your check
            subtotal.
          </p>

          {reservation.restaurant.has_frictionless && (
            <div>
              <h3>
                <Comment /> You will receive a text so you can view your live
                check.
              </h3>
              <p>
                This is a Frictionless restaurant: during your meal, track your
                spend, and adjust your tip.
              </p>

              <h3>
                <CreditCard /> At the end of the meal, you can leave without
                waiting for the check.
              </h3>
              <p>
                Your card will be automatically charged for any overage, tax,
                and tip.
              </p>
            </div>
          )}
        </div>

        <div className="section">
          <button className="nextButton" onClick={onUnderstand}>
            I understand
          </button>
        </div>

        <RemainingTime />
      </div>
    </>
  );
};

export default One01;
