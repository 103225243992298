import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card, { CardNames } from '../../../components/Card/Card.tsx';
import { Scrollable } from '../../../components/Scrollable/Scrollable.tsx';
import { useWidgetState, PaymentMethodsAtom } from '../lib/state.ts';
import { PaymentMethod } from '../../../lib/types.ts';

import styles from '../Booking/ScrollableItem.module.css';
import styles2 from './Payment.module.css';

const PaymentPicker: React.FC = ({
  isEvent = false,
}: {
  isEvent?: boolean;
}) => {
  const methods = useAtomValue(PaymentMethodsAtom);

  if (!methods) {
    return <></>;
  }

  return (
    <Scrollable>
      {methods.map((method: PaymentMethod) => (
        <PaymentPick key={method.id} method={method} isEvent={isEvent} />
      ))}
    </Scrollable>
  );
};

interface PickProps {
  method: PaymentMethod;
  isEvent: boolean;
}

const PaymentPick: React.FC<PickProps> = ({ method, isEvent = false }) => {
  const navigate = useNavigate();
  const [, updateWidget] = useWidgetState();
  const [hover, setHover] = useState<boolean>(false);

  const onEnter = () => setHover(true);
  const onLeave = () => setHover(false);
  const onClick = () => {
    updateWidget({
      payment_method: method.id,
    });

    if (isEvent) {
      navigate(`../eventconfirm`);
    } else {
      navigate(`../confirm`);
    }
  };

  const className = clsx(styles.scrollableItem, {
    [styles.hover]: hover,
  });

  return (
    <div
      className={className}
      onClick={onClick}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      <div className={clsx(styles.title, styles2.card)}>
        <span>{method.number}</span> <Card name={method.brand as CardNames} />
      </div>
      {method.nickname && <div className={styles.text}>{method.nickname}</div>}
    </div>
  );
};

export default PaymentPicker;
