import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { PinField } from 'react-pin-field';
import { useNavigate } from 'react-router-dom';
import Dorsia from '../../../assets/images/Dorsia.svg?react';
import { AuthResponse } from '../../../lib/types.ts';
import { verification, verify } from '../../../lib/api.ts';
import { AuthAtom, PhoneAtom, useFrictionlessState } from '../lib/state.ts';
import debounce from 'lodash/debounce';
import OtpInput from 'react-otp-input';
import styles from './Auth.module.css';

const Auth: React.FC = () => {
  const navigate = useNavigate();
  const phone = useAtomValue(PhoneAtom);
  const [sending, setSending] = useState<boolean | null>(null);
  const [, setState] = useFrictionlessState();
  const setAuth = useSetAtom(AuthAtom);
  const [confirmed, setConfirmed] = useState<boolean | null>(null);
  const pin_ref = useRef<HTMLInputElement[]>(null);
  const [otp, setOtp] = useState('');

  useEffect(() => {
    if (otp.length === 4) {
      debouncedAuth(otp);
    }
  }, [otp]);

  if (!phone) {
    navigate('../phone');
  }

  const onResendClick = (event: React.SyntheticEvent) => {
    // es-lint is finicky about promises and event handlers
    event.preventDefault();
    void onResend();
  };

  const onResend = async () => {
    if (!phone) {
      return;
    }

    setSending(true);

    await verify(phone.formatInternational()!, '');

    setSending(false);

    setTimeout(() => setSending(null), 5000);
  };

  const onComplete = async (code: string) => {
    if (!phone) {
      return;
    }

    try {
      const verification_response: AuthResponse = await verification(
        phone.formatInternational()!,
        code
      );

      Sentry.setUser({
        id: verification_response.user,
      });

      setAuth(verification_response);
      setState({
        unauthorized_error: null,
      });

      setConfirmed(true);

      setTimeout(() => navigate(`../details`), 300);
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.error(e.message);
        Sentry.captureException(e);
      }

      setConfirmed(false);

      if (pin_ref.current) {
        pin_ref.current.forEach(
          (input: HTMLInputElement) => (input.value = '')
        );
        pin_ref.current[0].focus();
      }
    }
  };

  const debouncedAuth = useCallback(debounce(onComplete, 300), []);

  if (!phone) {
    return <></>;
  }

  return (
    <div className={styles.section}>
      <Dorsia className={styles.dorsia} />

      <hr />

      <div className="content">
        <div className="section">
          <h3>
            We sent a 4-digit code to{' '}
            <span className={styles.noWrap}>{phone.formatNational()}</span>
          </h3>

          <p className={styles.info}>Enter the 4-digit code</p>

          <div className={styles.pinFieldContainer}>
            <OtpInput
              shouldAutoFocus
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderInput={(props) => <input {...props} />}
              inputStyle={styles.pinField}
              skipDefaultStyles
              inputType="number"
            />
          </div>

          {confirmed === false && (
            <div className={styles.error}>
              The code you entered is incorrect
            </div>
          )}

          {sending === true && (
            <a href="/" className={styles.again} onClick={onResendClick}>
              Sending...
            </a>
          )}
          {sending === false && (
            <a href="/" className={styles.again}>
              Sent
            </a>
          )}
          {sending === null && (
            <a href="/" className={styles.again} onClick={onResendClick}>
              Send Again
            </a>
          )}

          <button className="nextButton" disabled={!confirmed}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default Auth;
