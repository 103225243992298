import React, {Suspense, useEffect} from "react";
import {SWRConfig} from "swr";
import styles from '@/apps/Frictionless/Concierge/ConciergeMember.module.css'
import MagicLink from "@/apps/Frictionless/MagicLink/MagicLink.tsx";
import {useLocation} from "react-router-dom";
import RootLoader from "@/components/RootLoader/RootLoader.tsx";

const ConciergeMember: React.FC = () => {

    const location = useLocation();

    useEffect(() => {
        // Scroll to the top of the page any time the path changes
        document.getElementById('root')!.scrollIntoView();
    }, [location.pathname]);

    return (
        <>
            <div className={styles.container}>
                <Suspense fallback={<RootLoader/>}>
                    <SWRConfig
                        value={{
                            refreshInterval: 10000,
                            suspense: true
                        }}
                    >
                        <MagicLink/>
                    </SWRConfig>
                </Suspense>
            </div>
        </>
    )
}

export default ConciergeMember;