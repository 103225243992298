import React from "react";
import {useRestaurant} from "../lib/state.ts";

export const RestaurantNameLoader: React.FC = () => {
    return <h2>Loading...</h2>
}

const RestaurantName: React.FC = () => {
    const {restaurant} = useRestaurant();

    return <h2>{restaurant?.name}</h2>
}

export default RestaurantName