import React from "react";
import Header from "../Header/Header.tsx";

import styles from './Unavailable.module.css';

const Unavailable: React.FC = () => {
    return (
        <>
            <Header title="This table is no longer available" step={0} show_image={false} />

            <div className="content">
                <div className="section">
                    <p className={styles.text}>Sorry, but this table is no longer available. Please contact your reservationist.</p>
                </div>
            </div>

        </>
    )
}

export default Unavailable
