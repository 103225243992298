import clsx from "clsx";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import {useAtom} from "jotai";
import React, {useMemo, useState, useTransition} from "react";
import {Scrollable} from "../../../components/Scrollable/Scrollable.tsx";
import {updateBookingUrlStateAtom, useRestaurant, reloadResaurantKey} from "../lib/state.ts";
import {AvailDate} from "../../../lib/types.ts";
import styles from './ScrollableItem.module.css';

dayjs.extend(isToday);

export const ReservationDates: React.FC = () => {
    const {meta} = useRestaurant();

    if (!meta) {
        return <></>;
    }

    const filtered = meta.dates.filter((date: AvailDate) => date.amount_of_tables > 0);

    if (filtered.length === 0) {
        return (
            <div className="section">
                <b>There are no tables left to reserve.</b>
            </div>
        )
    }

    return (
        <Scrollable>
            {filtered.map((date: AvailDate) => <ReservationDate key={date.date} date={date} />)}
        </Scrollable>
    )
}

export const ReservationDatesLoader: React.FC = () => {
    return (
        <Scrollable>
            <div className={clsx(styles.scrollableItem, styles.loading)}>
                <div className={styles.title} />
            </div>
            <div className={clsx(styles.scrollableItem, styles.loading)}>
                <div className={styles.title} />
            </div>
            <div className={clsx(styles.scrollableItem, styles.loading)}>
                <div className={styles.title} />
            </div>
        </Scrollable>
    );
}

interface Props {
    date: AvailDate
}

const ReservationDate: React.FC<Props> = ({date}) => {
    const [, startTransition] = useTransition();
    const [temp, setTemp] = useAtom(updateBookingUrlStateAtom);
    const [hover, setHover] = useState<boolean>(false);
    const current_chosen = temp.date === date.date;

    const onEnter = () => setHover(true);
    const onLeave = () => setHover(false);
    const onClick = () => startTransition(() => {
        if (current_chosen) {
            return;
        }

        reloadResaurantKey();

        setTemp({
            date: date.date,
            availability: undefined,
            option: undefined
        });
    });

    const className = clsx(
        styles.scrollableItem,
        {
            [styles.selected]: current_chosen,
            [styles.hover]: hover
        }
    );

    const formatted =  useMemo(() => {
        const day_js = dayjs(date.date);
        if (day_js.isToday()) {
            return `Today, ${day_js.format('MMM D')}`;
        }

        return day_js.format('ddd, MMM D');
    }, [date]);


    return (
        <div className={className} onClick={onClick} onMouseEnter={onEnter} onMouseLeave={onLeave}>
            <div className={styles.title}>{formatted}</div>
        </div>
    );
}