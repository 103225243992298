import {AvailabilityOption, MetaAvailability, RestaurantResponseMeta} from "./types.ts";

/**
 * Adds diff-leys to seating options for simpler comparison
 * Adds min_seats and max_seats so they don't need to be computed on the fly
 * @param meta
 */
export const computeAvailability = (meta: RestaurantResponseMeta): RestaurantResponseMeta => {
    return {
        ...meta,
        availability: meta.availability.map((avail: MetaAvailability): MetaAvailability => {
            let min_seats = 999;
            let max_seats = 0;

            const options = avail.options.map((option: AvailabilityOption): AvailabilityOption => {
                min_seats = Math.min(min_seats, option.seats);
                max_seats = Math.max(max_seats, option.seats);
                return {
                    ...option,
                    diff_key: `${option.seating_id}-${option.seats}-${option.price}-${option.type}`
                }
            });

            return {
                ...avail,
                options,
                min_seats,
                max_seats
            }
        })
    }
}