import clsx from "clsx";
import React, {Suspense, useState} from "react";
import RootLoader from "../../components/RootLoader/RootLoader.tsx";
import Config from "../../lib/Config.ts";

import styles from './Iframe.module.css';

const IFrame: React.FC = () => {
    const [width,  setWidth] = useState<number>(800);
    const [height, setHeight] = useState<number>(1800);

    const onWidth = (event: React.ChangeEvent<HTMLInputElement>) => setWidth(parseFloat(event.currentTarget.value));
    const onHeight = (event: React.ChangeEvent<HTMLInputElement>) => setHeight(parseFloat(event.currentTarget.value));
    return (
        <>
            <div className={clsx("container", styles.container)}>
                <div className="content">

                    <div className={clsx("section", styles.form)}>
                        <h3>Iframe Test</h3>

                        <div className={styles.field}>
                            <label>Width</label>
                            <input type="number" className={styles.input} value={width} onChange={onWidth} min={50} max={2000} step={100} />
                        </div>

                        <div className={styles.field}>
                            <label>Height</label>
                            <input type="number" className={styles.input} value={height} onChange={onHeight} min={50} max={2000} step={100} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.iframe_container}>
                <Suspense fallback={<RootLoader />}>
                    <iframe title="Dorsia Booking" src={Config.data.uri.app} allow="clipboard-write" scrolling="no" style={{backgroundColor: "transparent", border: "none", height, width}} tabIndex={-1} aria-label="Booking by Dorsia"></iframe>
                </Suspense>
            </div>
        </>
    )
}

export default IFrame
