import clsx from "clsx";
import React from "react";
import {Link} from "react-router-dom";
import Header from "../Header/Header.tsx";
import Config from "../../../lib/Config.ts";

import styles from './Index.module.css';

const Index: React.FC = () => {
    return (
        <div className="container">
            <PerEnv />
        </div>
    )
}

const PerEnv: React.FC = () => {
    switch (Config.data.environment) {
        case 'local':
            return (
                <>
                    <Header title="Local Test" step={0} show_image={false} />
                    <div className={clsx("content", styles.index)}>
                        <Link to="./76">Gekkō</Link>
                    </div>
                </>
            );

        case 'staging':
            return (
                <>
                    <Header title="Staging Test" step={0} show_image={false} />
                    <div className={clsx("content", styles.index)}>
                        <Link to="./76">Gekkō</Link>
                    </div>
                </>
            );

        default:
        case 'production':
            return (
                <>
                    <Header title="" step={0} show_image={false} />
                    <div className={clsx("content", styles.index)}>
                        <Link to="./286">Tao Asian Bistro</Link>
                        <Link to="./287">Beauty and Essex</Link>
                        <Link to="./288">Hakkasan</Link>
                        <Link to="./289">Lavo</Link>
                    </div>
                </>
            );
    }
}

export default Index
