import {useAtomValue} from "jotai";
import React from "react";
import {ReservationAtom} from "../../lib/state.ts";
import styles from "./HeaderImage.module.css";

export const HeaderImageLoader: React.FC = () => {
    return <div className={styles.image} style={{backgroundColor: '#DFDFDF'}}></div>
}

const HeaderImage: React.FC = () => {
    const reservation = useAtomValue(ReservationAtom);

    if (!reservation) {
        return <HeaderImageLoader />
    }

    return <img src={reservation.restaurant.wide_crop_image_url} className={styles.image} alt="" width="100%" height={200} />;
}

export default HeaderImage