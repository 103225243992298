import React, { Suspense } from 'react';
import HeaderImage, { HeaderImageLoader } from './HeaderImage/HeaderImage.tsx';
import styles from './Header.module.css';
import Dorsia from '../../../assets/images/Dorsia.svg?react';

interface Props {
  title: string;
  step: number;
  show_image?: boolean;
}

const Header: React.FC<Props> = ({ title, step, show_image = true }) => {
  return (
    <>
      <div className={styles.header}>
        <span className={styles.booking}>Booking by</span>
        <Dorsia className={styles.dorsia} />
        <hr />
        <div className={styles.title}>
          <h1>{title}</h1>
          {step > 0 && <div className={styles.info}>Step {step} of 5</div>}
        </div>
      </div>

      {show_image && (
        <Suspense fallback={<HeaderImageLoader />}>
          <HeaderImage />
        </Suspense>
      )}
    </>
  );
};

export default Header;
