interface Configuration {
  environment: 'local' | 'staging' | 'production';
  build: string;
  uri: {
    api: string;
    app: string;
    web: string;
  };
  stripe: {
    key: string;
  };
  mapbox: {
    username: string;
    access_token: string;
    style_id: string;
  };
}

// The purpose of this file is to define the structure of the conf using
// a demo file and then load the file with the actual values from the environment
// This gives us proper TS type hints while also allowing us to load the real data dynamically
export default class Config {
  static data: Configuration;

  public static setFromFile = async () => {
    try {
      if (!Config.data) {
        const response = await fetch('/config.json');
        if (response.ok) {
          Config.data = (await response.json()) as Configuration;
        }
      }
    } catch (e) {
      console.error('Had trouble loading config file!', e);
    }
  };
}
