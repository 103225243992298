import * as Sentry from "@sentry/react";
import {atom, useAtom, useAtomValue} from "jotai";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import RelativeTime from "dayjs/plugin/relativeTime.js";
import {atomWithStorage} from "jotai/utils";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {cancelReservationConfirm} from "../../../lib/api.ts";
import {ApiError} from "../../../lib/ApiError.ts";
import {CancelConfirmResponse, CancelFinishResponse} from "../../../lib/types.ts";

dayjs.extend(isToday);
dayjs.extend(RelativeTime);

export const SignatureAtom = atomWithStorage<Record<string, string>>('cancel_signature', {});
export const CancellationAtom = atom<CancelConfirmResponse | null>(null);
export const CanceledAtom = atom<CancelFinishResponse | null>(null);

export const useSignature = (uuid: string) => {
    const signatures: Record<string, string> = useAtomValue(SignatureAtom);
    return signatures[uuid] ?? null;
}

export const useCancellation = () => {
    const navigate = useNavigate();
    const {uuid} = useParams();
    const [cancellation, setCancellation] = useAtom(CancellationAtom);

    const signature = useSignature(uuid!);

    useEffect(() => {
        if (!signature) {
            return;
        }

        if (!uuid) {
            return;
        }

        const get = async () => {
            try {
                const response = await cancelReservationConfirm(signature, uuid);

                if (response) {
                    const now = dayjs().utc().add(7, 'day');
                    const short_cancellation = dayjs(response.reservation.reservation_at).isBefore(now);

                    const symbol = response.reservation.restaurant.currency_symbol;
                    const total = Math.floor(response.reservation.table_minimum * 0.05)
                    const total_after_cancellation = `${symbol}${total.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}`

                    setCancellation({
                        ...response,
                        short_cancellation,
                        total_after_cancellation
                    });
                }
            } catch (e: unknown) {
                if (e instanceof ApiError && e.type === 'AlreadyCanceledReservationErrorResponse') {
                    navigate(`./canceled/`);
                } else if (e instanceof Error) {
                    console.error('Get Reservation Error', e);
                    Sentry.captureException(e, {
                        extra: {
                            uuid
                        }
                    });
                } else {
                    console.error('Unrecognized Error', e);
                    Sentry.captureException(e, {
                        extra: {
                            uuid
                        }
                    });
                }
            }
        };

        void get();
    }, [signature, uuid]);

    return cancellation
}