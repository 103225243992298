import React, {Suspense, useEffect} from "react";
import {Outlet, useLocation} from "react-router-dom";
import RootLoader from "../../components/RootLoader/RootLoader.tsx";
import Config from "../../lib/Config.ts";
import {useCancellation} from "./lib/state.ts";

const CancelLink: React.FC = () => {
    useCancellation();
    const {pathname} = useLocation();

    useEffect(() => {
        // Scroll to the top of the page any time the path changes
        document.getElementById('root')!.scrollIntoView();
    }, [pathname]);

    return (
        <>
            <div className="container">
                <Suspense fallback={<RootLoader />}>
                    <Outlet />
                </Suspense>
            </div>

            <div className="build">{Config.data.build}</div>
        </>
    )
}

export default CancelLink
