import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import React, { Suspense } from 'react';
import { useMediaQuery } from 'react-responsive';
import { getMapUrl } from '@/lib/getMapUrl.ts';
import { getPhone } from '@/lib/getPhone.ts';
import Header from '../Header/Header.tsx';
import { ReservationAtom } from '../lib/state.ts';
import styles from './Confirmation.module.css';
import NeedToKnows from '@/components/NeedToKnows/NeedToKnows.tsx';
import DirectionsIcon from '@/assets/icons/Back_light.svg?react';
import GlobeIcon from '@/assets/icons/globe_light.svg?react';
import AddCalendar from '@/assets/icons/AddCalendar.svg?react';
import { Guests } from './Guests.tsx';
import Config from '@/lib/Config.ts';
import DownloadDorsia from '@/components/DownloadDorsia/DownloadDorsia.tsx';

interface CalendarEvent {
  title: string;
  description: string;
  startDate: Date;
  endDate?: Date;
  durationInMinutes?: number;
  address?: string;
}

const MINUTE_IN_MS = 60 * 1000;

const Confirmation: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
  const reservation = useAtomValue(ReservationAtom);
  const width = isMobile ? 800 : 258;
  const height = isMobile ? 427 : 263;
  const map_url = getMapUrl(width, height, reservation?.restaurant, isRetina);

  if (!reservation) {
    return (
      <>
        <Header title="Loading confirmation" step={0} />
        <div className="content"></div>
      </>
    );
  }

  const flat_address = reservation.restaurant.address
    ? reservation.restaurant.address.replace(/[\r\n]+/, '')
    : '';
  const rest_phone = getPhone(reservation.restaurant.phone);

  const eventCal: CalendarEvent = {
    title: reservation.restaurant.name,
    description: `
    ${reservation.reservation_at_tzd.format('h:mm a')}\n
    ${reservation.party_size} guests\n
    ${reservation.table_type}`,
    startDate: reservation.reservation_at_tzd.toDate(),
    durationInMinutes: 120,
    address: flat_address,
  };

  function addCalendar() {
    const googleurl = generateGoogleCalendarUrl(eventCal);
    const icsurl = generateIcsCalendarFile(eventCal);
    console.log('icurl', icsurl);
    window.open(googleurl, '_blank');
  }

  function addMinutesToDate(date: Date, minutes: number) {
    return new Date(date.getTime() + minutes * MINUTE_IN_MS);
  }

  function getEndTime(calendarEvent: CalendarEvent) {
    return (
      calendarEvent.endDate ??
      addMinutesToDate(
        calendarEvent.startDate,
        calendarEvent.durationInMinutes || 120
      )
    );
  }

  function formatDateForCalendarUrl(date: Date) {
    return date.toISOString().replace(/-|:|\.\d+/g, '');
  }

  function generateIcsCalendarFile(calendarEvent: CalendarEvent) {
    const startDate = formatDateForCalendarUrl(calendarEvent.startDate);
    const endDate = formatDateForCalendarUrl(getEndTime(calendarEvent));

    const encodedUrl = encodeURI(
      `data:text/calendar;charset=utf8,${[
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        `URL:${document.URL}`, // TODO: insert video app url here
        `DTSTART:${startDate || ''}`,
        `DTEND:${endDate || ''}`,
        `SUMMARY:${calendarEvent.title || ''}`,
        `DESCRIPTION:${calendarEvent.description || ''}`,
        `LOCATION:${calendarEvent.address ?? ''}`,
        'END:VEVENT',
        'END:VCALENDAR',
      ].join('\n')}`
    );

    return encodedUrl;
  }

  function generateGoogleCalendarUrl(calendarEvent: CalendarEvent) {
    const startDate = formatDateForCalendarUrl(calendarEvent.startDate);
    const endDate = formatDateForCalendarUrl(getEndTime(calendarEvent));

    const encodedUrl = encodeURI(
      [
        'https://www.google.com/calendar/render',
        '?action=TEMPLATE',
        `&text=${calendarEvent.title || ''}`,
        `&dates=${startDate || ''}`,
        `/${endDate || ''}`,
        // TODO: append video appointment link to description
        `&details=${calendarEvent.description}\n`,
        `&location=${calendarEvent.address ?? ''}`,
        '&sprop=&sprop=name:',
      ].join('')
    );

    return encodedUrl;
  }

  function getDownloadLink() {
    const prodLink = 'https://dorsia.app.link/referral_hwood';
    const devLink = 'https://r0cio.app.link/referral_hwood';
    const standardLink =
      'https://apps.apple.com/us/app/dorsia-members-club/id1589267208';

    if (reservation?.restaurant.id === 322) {
      if (
        Config.data.environment === 'local' ||
        Config.data.environment === 'staging'
      ) {
        return devLink;
      }
    }

    if (
      reservation?.restaurant.id === 494 &&
      Config.data.environment === 'production'
    ) {
      return prodLink;
    }

    return standardLink;
  }

  return (
    <>
      <Header
        title={
          reservation.cancelled ? 'Booking cancelled' : 'Booking confirmed'
        }
        step={0}
        isEvent={reservation?.restaurant.type == 'event'}
      />

      <div className="content">
        <div className={styles.grid}>
          <div className={styles.resinfo}>
            <h2>{reservation.restaurant.name}</h2>

            <div className={styles.detailssection}>
              <div
                className={reservation.cancelled ? 'strike section' : 'section'}
              >
                <div>
                  {reservation.reservation_at_tzd.format('ddd, MMMM D')}
                </div>
                <div>{reservation.reservation_at_tzd.format('h:mm a')}</div>
                <div>{reservation.party_size} guests</div>
                <div>{reservation.table_type}</div>
              </div>

              <div className={styles.detailsactions}>
                <div className={styles.addcalendar}>
                  <a
                    onClick={addCalendar}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AddCalendar />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {!isMobile && (
            <div className={styles.mapSquareContainer}>
              {map_url && (
                <a
                  href={`https://maps.google.com/?q=${flat_address}`}
                  target="_blank"
                >
                  <img
                    className={styles.map}
                    src={map_url.toString()}
                    alt="Map"
                  />
                </a>
              )}
            </div>
          )}
        </div>

        {!isMobile && <hr />}

        <Guests guests={reservation.guests} reservation={reservation} />

        {!reservation.cancelled && (
          <div className={clsx('section', styles.info)}>
            <div className={styles.resdetails}>
              <h3>{reservation.restaurant.name}</h3>

              {!!rest_phone && (
                <div className={styles.phone}>
                  <a href={rest_phone.getURI()} className={styles.link}>
                    {rest_phone.formatNational()}
                  </a>
                </div>
              )}
              <Address address={reservation.restaurant.address} />
              {reservation.restaurant.website && (
                <a
                  href={reservation.restaurant.website}
                  target="_blank"
                  className={styles.link}
                >
                  {reservation.restaurant.website}
                </a>
              )}
            </div>

            {isMobile ? (
              <div className={styles.mapContainer}>
                {map_url && (
                  <a
                    href={`https://maps.google.com/?q=${flat_address}`}
                    target="_blank"
                  >
                    <img
                      className={styles.map}
                      src={map_url.toString()}
                      alt="Map"
                    />
                  </a>
                )}
              </div>
            ) : (
              <div className={styles.directionswrapper}>
                <a
                  href={`https://maps.google.com/?q=${flat_address}`}
                  target="_blank"
                  className={styles.directionsbutton}
                >
                  Directions
                </a>
              </div>
            )}

            {isMobile && (
              <div className={styles.mapinfo}>
                <div className={styles.mapinfoblock}>
                  <a
                    href={`https://maps.google.com/?q=${flat_address}`}
                    target="_blank"
                  >
                    <DirectionsIcon className={styles.infoIcon} />
                  </a>
                  <a
                    href={`https://maps.google.com/?q=${flat_address}`}
                    target="_blank"
                  >
                    Directions
                  </a>
                </div>
                {reservation.restaurant.website && (
                  <div className={styles.mapinfoblock}>
                    <GlobeIcon className={styles.infoIcon} />
                    <a href={reservation.restaurant.website} target="_blank">
                      Website
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <hr />

        {!reservation.cancelled && (
          <div className={clsx('section', styles.needToKnow)}>
            <h3>Need to know</h3>

            <Suspense fallback={<Loading />}>
              <NeedToKnows
                need_to_knows={reservation.restaurant.need_to_knows}
              />
            </Suspense>
          </div>
        )}

        <DownloadDorsia
          reservation={reservation.restaurant.id}
        />
      </div>
    </>
  );
};

interface AddressProps {
  address: string;
}

const Address: React.FC<AddressProps> = ({ address }) => {
  const split = address.replace('\r', '').split('\n');

  return (
    <>
      {split.map((line: string, i: number) => (
        <div key={i}>{line}</div>
      ))}
    </>
  );
};

const Loading: React.FC = () => {
  return <div>Wait...</div>;
};

export default Confirmation;
