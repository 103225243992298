import React from "react";
import Dorsia from "../../assets/images/Dorsia.svg?react"
import styles from "./RootLoader.module.css";

const RootLoader: React.FC = () => {
    return (
        <>
            <div className={styles.header}>
                <span className={styles.booking}>Booking by</span>
                <Dorsia className={styles.dorsia} />

                <hr />

                <div className={styles.title}>
                    <h1>Loading...</h1>
                </div>
            </div>

            <div className="content">
            </div>
        </>
    );
}

export default RootLoader