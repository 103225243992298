import * as Sentry from '@sentry/react';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import RelativeTime from 'dayjs/plugin/relativeTime.js';
import { atomWithStorage } from 'jotai/utils';
import { PhoneNumber } from 'libphonenumber-js';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getConciergeMemberReservationPreview, getConciergeMemberReservationReceipt,
  updateConciergeMemberReservationAdditionalTip,
  getReservationPreview,
  getReservationReceipt,
  updateReservationAdditionalTip,
  updateReservationTip, updateConciergeMemberReservationTip,
} from '../../../lib/api.ts';

import { ApiError } from '../../../lib/ApiError.ts';
import {
  AuthResponse,
  Post_Tip_Type,
  ReservationPreview,
  ReservationReceipt,
  ReservationReceiptResponse,
} from '../../../lib/types.ts';
import {
  Currency,
  FRICTIONLESS_STATE_VERSION,
  FrictionlessState,
} from './types.ts';

dayjs.extend(isToday);
dayjs.extend(RelativeTime);

export const NowAtom = atom<Dayjs>(dayjs());
export const AuthAtom = atomWithStorage<AuthResponse | undefined>(
  'frictionless_auth',
  undefined
);
export const PhoneAtom = atom<PhoneNumber | null>(null);
export const ReservationPreviewAtom = atom<ReservationPreview | null>(null);
export const ReceiptAtom = atom<ReservationReceipt | null>(null);
export const LoadReceiptAtom = atom<Dayjs>(dayjs());

const getExpiration = (): Dayjs => dayjs().add(30, 'minute');

export const useInit = () => {
  const setNow = useSetAtom(NowAtom);

  useEffect(() => {
    const intervalId = setInterval(() => setNow(dayjs().utc()), 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
};

export const FrictionlessStateAtom = atomWithStorage<FrictionlessState>(
  'frictionless',
  {
    version: FRICTIONLESS_STATE_VERSION,
    expires: getExpiration(),
    currency: 'USD',
    unauthorized_error: null,
  }
);

export const updateFrictionlessStateAtom = atom(
  null,
  (get, set, update: Partial<FrictionlessState>) => {
    const currentState = get(FrictionlessStateAtom);
    set(FrictionlessStateAtom, {
      ...currentState,
      ...update,
      expires: getExpiration(),
    });
  }
);

export const useFrictionlessState = (): [
  FrictionlessState,
  (partial: Partial<FrictionlessState>) => void
] => {
  const state = useAtomValue(FrictionlessStateAtom);
  const setState = useSetAtom(updateFrictionlessStateAtom);

  const update = (partial: Partial<FrictionlessState>) => {
    setState(partial);
  };

  return [state, update];
};

export const usePreview = () => {
  const { uuid } = useParams();
  const [auth] = useAtom(AuthAtom);

  const setPreview = useSetAtom(ReservationPreviewAtom);

  useEffect(() => {
    if (!uuid) {
      return;
    }

    if (!auth?.token) {
      return;
    }

    const getPreview = async () => {
      try {
        const endpoint = (auth.is_concierge_member) ? getConciergeMemberReservationPreview : getReservationPreview;
        const response = await endpoint(auth.token, uuid);
        if (response) {
          setPreview(response.data);
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          console.error('Get Reservation Preview Error', e);
        } else {
          console.error('Unrecognized Error', e);
        }
        Sentry.captureException(e);
      }
    };

    void getPreview();
  }, [auth?.token, uuid]);
};

export const useReceipt = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [auth, setAuth] = useAtom(AuthAtom);
  const setPhone = useSetAtom(PhoneAtom);
  const [, setState] = useFrictionlessState();
  const [reload, setReload] = useAtom(LoadReceiptAtom);
  const setReceipt = useSetAtom(ReceiptAtom);

  useEffect(() => {
    const intervalId = setInterval(() => setReload(dayjs()), 1000 * 60);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (!uuid) {
      return;
    }

    if (!auth?.token) {
      return;
    }

    const getReceipt = async () => {
      try {
        const endpoint = (auth.is_concierge_member) ? getConciergeMemberReservationReceipt : getReservationReceipt;

        const response = await endpoint(auth.token, uuid);
        if (response?.data) {
          setReceipt(response.data);

          let currency: Currency = 'USD';
          switch (response.data.restaurant.currency_symbol) {
            case '$':
              currency = 'USD';
              break;
            case '£':
              currency = 'GBP';
              break;
          }

          setState({ uuid, currency });
        } else {
          Sentry.captureMessage('Missing receipt Data', {
            extra: {
              uuid,
              response,
            },
          });
        }
      } catch (e: unknown) {
        if (e instanceof ApiError) {
          switch (e.type) {
            case 'UnauthorizedErrorResponse':
              Sentry.setUser(null);
              setAuth(undefined);
              setPhone(null);
              setState({ uuid });

              navigate('./phone');
              break;

            case 'ReservationReceiptNotReady':
              console.info('Reservation Receipt is not ready');
              break;

            default:
              console.error('Get Receipt Error', e);
              Sentry.captureException(e, {
                extra: {
                  uuid,
                },
              });
              break;
          }
        } else if (e instanceof Error) {
          console.error('Get Receipt Error', e);
          Sentry.captureException(e, {
            extra: {
              uuid,
            },
          });
        } else {
          console.error('Unrecognized Error', e);
          Sentry.captureException(e, {
            extra: {
              uuid,
            },
          });
        }
      }
    };

    void getReceipt();
  }, [auth?.token, uuid, reload]);
};

interface useUpdateTipResult {
  percentage: number | null;
  custom: number | null;
  updateTip: (amount: number, type: Post_Tip_Type) => Promise<boolean>;
}

export const useUpdateTip = (): useUpdateTipResult => {
  const { uuid } = useParams();
  const auth = useAtomValue(AuthAtom);
  const [receipt, setReceipt] = useAtom(ReceiptAtom);

  const updateTip = async (
    amount: number,
    type?: Post_Tip_Type
  ): Promise<boolean> => {
    if (!uuid) {
      return false;
    }

    if (!auth?.token) {
      return false;
    }

    if (!receipt) {
      return false;
    }

    if (receipt.receipt.tip_included) {
      response = await endpoint(auth.token, uuid, {
        amount: amount,
        percentage: type === 'percentage',
      });
    }
    let response: ReservationReceiptResponse;


    if (receipt.receipt.tip_included) {
      const endpoint = (auth.is_concierge_member) ? updateConciergeMemberReservationAdditionalTip : updateReservationAdditionalTip;
      response = await endpoint(auth.token, uuid, {
        amount: amount,
        percentage: type === 'percentage',
      });
    } else {
      const endpoint = (auth.is_concierge_member) ? updateConciergeMemberReservationTip : updateReservationTip;
      response = await endpoint(auth.token, uuid, {
        tip_amount: amount,
        percentage: type === 'percentage',
        custom: type === 'custom',
      });
    }

    if (response) {
      setReceipt(response.data);
    }

    return true;
  };

  let custom: number | null = null;
  let percentage: number | null = null;
  if (receipt?.receipt.tip_included) {
    if (receipt?.receipt.additional_tip.percentage) {
      percentage = receipt?.receipt.additional_tip.value ?? null;
    } else {
      custom = receipt?.receipt.additional_tip.amount ?? null;
    }
  } else {
    percentage = receipt?.receipt.tip_percentage ?? null;
    custom = receipt?.receipt.custom_tip ?? null;
  }

  return {
    percentage,
    custom,
    updateTip,
  };
};
