import React from "react";
import CalendarLightIcon from "../../assets/icons/CalendarLightIcon.svg?react"
import CardIcon from "../../assets/icons/CardIcon.svg?react"
import CheckIcon from "../../assets/icons/CheckIcon.svg?react"
import CircleCheckmarkIcon from "../../assets/icons/CircleCheckmarkIcon.svg?react"
import ClosedIcon from "../../assets/icons/ClosedIcon.svg?react"
import DrinkIcon from "../../assets/icons/DrinkIcon.svg?react"
import FrictionlessIcon from "../../assets/icons/FrictionlessIcon.svg?react"
import GenericInfoIcon from "../../assets/icons/GenericInfoIcon.svg?react"
import PlateIcon from "../../assets/icons/PlateIcon.svg?react"
import SuitIcon from "../../assets/icons/SuitIcon.svg?react"
import TableIcon from "../../assets/icons/TableIcon.svg?react"

export type NeedToKnowNames = 'calendar'
                            | 'card'
                            | 'check'
                            | 'circle-checkmark'
                            | 'closed'
                            | 'drink'
                            | 'frictionless'
                            | 'plate'
                            | 'suit'
                            | 'table';

interface Props {
    name: NeedToKnowNames,
    className?: string
}

const NeedToKnowIcon: React.FC<Props> = ({name, className}) => {
    switch(name) {
        case 'calendar':         return <CalendarLightIcon className={className} />;
        case 'card':             return <CardIcon className={className} />;
        case 'check':            return <CheckIcon className={className} />;
        case 'circle-checkmark': return <CircleCheckmarkIcon className={className} />;
        case 'closed':           return <ClosedIcon className={className} />;
        case 'drink':            return <DrinkIcon className={className} />;
        case 'frictionless':     return <FrictionlessIcon className={className} />;
        case 'plate':            return <PlateIcon className={className} />;
        case 'suit':             return <SuitIcon className={className} />;
        case 'table':            return <TableIcon className={className} />;
        default:                 return <GenericInfoIcon className={className} />
    }
}

export default NeedToKnowIcon
