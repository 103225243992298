import * as Sentry from "@sentry/react";
import {useAtom, useAtomValue} from "jotai";
import React, {Suspense, useEffect} from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import RootLoader from "../../components/RootLoader/RootLoader.tsx";
import Config from "../../lib/Config.ts";
import {useInit, AuthAtom, useReceipt, LoadReceiptAtom, usePreview} from "./lib/state.ts";

import styles from './Frictionless.module.css';

const Frictionless: React.FC = () => {
    useInit();
    usePreview();
    useReceipt();

    const {uuid} = useParams();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const [auth, setAuth] = useAtom(AuthAtom);
    const checked = useAtomValue(LoadReceiptAtom);

    useEffect(() => {
        // Scroll to the top of the page any time the path changes
        document.getElementById('root')!.scrollIntoView();
    }, [pathname]);

    const onLogout = () => {
        Sentry.setUser(null);
        setAuth(undefined);
        navigate(`/reservation/${uuid}/receipt`);
    }

    return (
        <>
            <div className={styles.container}>
                <Suspense fallback={<RootLoader />}>
                    <Outlet />
                </Suspense>
            </div>

            {Config.data.environment !== 'production' && (
                <div className="resetArea">
                    {!!auth && <button onClick={onLogout} className="resetButton">Log Out</button>}
                    {!!checked && <div>Last Checked: {checked.format('h:mm:ss a')}</div>}
                </div>
            )}

            <div className="build">{Config.data.build}</div>
        </>
    )
}

export default Frictionless
