import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getPhone } from '../../../lib/getPhone.ts';
import { reloadPaymentMethodsAtom, PaymentMethodsAtom } from '../lib/state.ts';
import Header from '../Header/Header.tsx';
import RestaurantName from '../RestaurantName/RestaurantName.tsx';
import Config from '../../../lib/Config.ts';
import {
  AuthAtom,
  useWidgetState,
  useRestaurantId,
  usePaymentMethods,
  useUserDetails,
  useRestaurant,
} from '../lib/state.ts';
import { setPaymentMethod, setupIntent } from '../../../lib/api.ts';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  loadStripe,
  Stripe,
  StripeElementsOptions,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { createEventReservationPreview } from '@/lib/api.ts';
import styles from './Payment.module.css';
import PaymentPicker from './PaymentMethods.tsx';

const EventPayment: React.FC = () => {
  usePaymentMethods();
  const { restaurant } = useRestaurant();
  const [widget, updateWidget] = useWidgetState();
  const user = useUserDetails();
  const auth = useAtomValue(AuthAtom);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const methods = useAtomValue(PaymentMethodsAtom);

  useEffect(() => {
    const waitForIt = async () => {
      setStripe(await loadStripe(Config.data.stripe.key));
    };
    void waitForIt();
  }, []);

  useEffect(() => {
    void createReservation();
  }, []);

  const stripe_options: StripeElementsOptions = {
    mode: 'setup',
    currency: 'usd', // TODO: Fix currency
    appearance: {
      theme: 'stripe',

      variables: {
        fontFamily: 'Sailec',
        borderRadius: '10px',
        colorBackground: '#FFFFFF',
        spacingGridColumn: '30px',
        spacingGridRow: '30px',
      },

      rules: {
        '.Label': {
          fontSize: '16px',
          marginBottom: '15px',
          fontFamily: 'Sailec',
        },

        '.Input': {
          boxShadow: 'none',
          padding: '10px',
          fontSize: '16px',
          fontFamily: 'Sailec',
        },
      },
    },
    fonts: [
      {
        family: 'Sailec',
        src: 'url(https://book.dorsia.com/fonts/stripe-font.otf)',
      },
    ],
  };

  if (!restaurant || !stripe || !auth || !user) {
    if (Config.data.environment !== 'production') {
      return (
        <>
          <code>Restaurant: {restaurant ? 'yes' : 'no'}</code>
          <code>Option: {widget.option ? 'yes' : 'no'}</code>
          <code>Stripe: {stripe ? 'yes' : 'no'}</code>
          <code>Auth: {auth ? 'yes' : 'no'}</code>
          <code>User: {user ? 'yes' : 'no'}</code>
        </>
      );
    }

    return <></>;
  }

  async function createReservation() {
    if (auth && widget) {
      const ticketItemsSelected = [widget.eventInfo?.ticektItemId];
      const eventId = widget.event?.id ?? 0;

      try {
        const response = await createEventReservationPreview(
          auth?.token,
          { items: ticketItemsSelected },
          eventId,
          widget.confirm_body?.restaurant_id
        );

        if (response) {
          updateWidget({
            ...widget,
            confirm_body: {
              ...widget.confirm_body,
              date: response.data.event.datetime_local__formatted.date,
              time: response.data.event.time,
            },
            date: response.data.event.date,
            option: {
              ...widget.option,
            },
            event: {
              ...widget.event,
              date: response.data.event.date,
              name: response.data.event.name,
              datetime_local__formatted:
                response.data.event.datetime_local__formatted,
              time: response.data.event.datetime_local__formatted.time,
            },
            eventInfo: {
              ...widget.eventInfo,
              fee_restaurant: response.data.fee_restaurant,
              fee_restaurant__currency: response.data.fee_restaurant__currency,
              items: response.data.items,
              party_size: response.data.party_size,
              restaurant: response.data.restaurant,
              service_charges: response.data.service_charges,
              service_charges__currency:
                response.data.service_charges__currency,
              tax_amount: response.data.tax_amount,
              tax_amount__currency: response.data.tax_amount__currency,
              tax_rate: response.data.tax_rate,
              total_paid: response.data.total_paid,
              total_paid__currency: response.data.total_paid__currency,
            },
          });
        }
      } catch (e: unknown) {
        console.log('error', e);
      }
    }
  }

  const user_phone = getPhone(user.phone, widget.phone_country);

  return (
    <>
      <Header title="Add payment method" step={3} show_image={false} />

      {widget?.payment_error && (
        <div className={clsx('content', styles.header_error)}>
          There was an issue with your payment method.
        </div>
      )}

      <div className="content">
        <div>
          <RestaurantName />
          <div>{restaurant.address}</div>
        </div>

        <div className={styles.grid}>
          <div className="section">
            <h3>Reservation details</h3>
            <div>
              {widget.event?.datetime_local__formatted && (
                <div>
                  <div>
                    {widget.event?.datetime_local__formatted?.date_dow_long}
                    {`, `}
                    {widget.event?.datetime_local__formatted?.date_long}
                  </div>
                  <div>{widget.event?.datetime_local__formatted?.time}</div>
                </div>
              )}
              <div>
                {widget.eventInfo?.party_size}{' '}
                {widget.eventInfo?.party_size === 1 ? 'guest' : 'guests'}
              </div>
              <div>{widget.eventInfo?.total_paid__currency}</div>
            </div>

            <div className={styles.edit}>
              <Link to={'../'} className="lightButton">
                Edit booking
              </Link>
            </div>
          </div>

          <div className="section">
            <h3>Your details</h3>

            <div>
              {user.first_name} {user.last_name}
            </div>
            <div>{!!user_phone && user_phone.formatNational()}</div>
            <div>{user.email}</div>

            <div className={styles.edit}>
              <Link to={'../info'} className="lightButton">
                Edit your details
              </Link>
            </div>
          </div>
        </div>

        {methods.length > 0 && (
          <div className="section">
            <h3>Choose an existing payment method</h3>

            <PaymentPicker isEvent={true} />
          </div>
        )}

        <div className="section">
          <h3>Enter your credit card</h3>

          <Elements stripe={stripe} options={stripe_options}>
            <CheckoutForm />
          </Elements>
        </div>
      </div>
    </>
  );
};

function CheckoutForm({ isEvent = false }: { isEvent: boolean }) {
  const id = useRestaurantId();
  const auth = useAtomValue(AuthAtom);
  const reloadPaymentMethods = useSetAtom(reloadPaymentMethodsAtom);
  const [booking, setBooking] = useState<boolean>(false);
  const user = useUserDetails();
  const [name, setName] = useState<string>(
    `${user.first_name} ${user.last_name}`
  );
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [widget, updateWidget] = useWidgetState();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    widget?.payment_error
  );

  useEffect(() => {
    setName(`${user.first_name} ${user.last_name}`);
  }, [user.first_name, user.last_name]);

  const onName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };

  const onSubmit = (event: React.SyntheticEvent) => {
    // es-lint is finicky about promises and event handlers
    event.preventDefault();
    void handleSubmit();
  };

  const onClick = (event: React.SyntheticEvent) => {
    // es-lint is finicky about promises and event handlers
    event.preventDefault();
    void handleSubmit();
  };

  const handleSubmit = async () => {
    setBooking(true);

    setErrorMessage(undefined);

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Trigger form validation and wallet collection
    const { error } = await elements.submit();

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setBooking(false);
      return;
    }

    try {

      const response = await setupIntent(auth!.token);

      if (!response?.setupIntent) {
        Sentry.captureMessage('Widget: Problem occurred with api/setup-intent');
        setErrorMessage(
          'Sorry, there was an issue.  Please try again in a moment.'
        );
        setBooking(false);
        return;
      }

      const returnUrl = `${Config.data.uri.app}/restaurants/${id}/eventconfirm`;

      const { setupIntent: result, error } = await stripe.confirmSetup({
        elements,
        clientSecret: response.setupIntent.client_secret,
        confirmParams: {
          return_url: returnUrl,
          payment_method_data: {
            billing_details: {
              name,
              email: user.email,
            },
          },
        },
        redirect: 'if_required',
      });


      if (error) {
        setErrorMessage(error.message);
        setBooking(false);
        return;
      }

      const payment_method = result?.payment_method as string;
      const dorsia_payment = await setPaymentMethod(auth!.token, {
        paymentMethodId: payment_method,
      });

      updateWidget({
        payment_method: dorsia_payment.payment_id,
      });

      setBooking(false);
      reloadPaymentMethods();

      navigate(`../eventconfirm`);
    } catch (e) {
      Sentry.captureException(e);
      setErrorMessage(
        'Sorry, there was an issue.  Please try again in a moment.'
      );
      setBooking(false);
    }
  };

  const payment_options: StripePaymentElementOptions = {
    defaultValues: {
      billingDetails: {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        phone: user.phone,
      },
    },
    fields: {
      billingDetails: {
        name: 'never',
      },
    },
    wallets: {
      applePay: 'never', // doesn't work in iframe
    },
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        {errorMessage && <div className={styles.error}>{errorMessage}</div>}

        <div className={styles.field}>
          <label>Cardholder name</label>
          <input type="text" value={name} onChange={onName} />
        </div>

        <PaymentElement options={payment_options} />
      </form>

      <button
        className="nextButton"
        onClick={onClick}
        disabled={!stripe || booking}
      >
        Next
      </button>
    </>
  );
}

export default EventPayment;
