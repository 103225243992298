import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card, { CardNames } from '../../../components/Card/Card.tsx';
import { Scrollable } from '../../../components/Scrollable/Scrollable.tsx';
import { PaymentMethodsAtom, useDirectLinkState } from '../lib/state.ts';
import { GetReservation, PaymentMethod } from '../../../lib/types.ts';

import styles from './ScrollableItem.module.css';
import styles2 from './Payment.module.css';

function PaymentPicker({ reservation }: { reservation?: GetReservation }) {
  const methods = useAtomValue(PaymentMethodsAtom);

  return (
    <Scrollable>
      {methods.map((method: PaymentMethod) => (
        <PaymentPick
          key={method.id}
          method={method}
          reservation={reservation}
        />
      ))}
    </Scrollable>
  );
}

interface PickProps {
  method: PaymentMethod;
  reservation?: GetReservation;
}

const PaymentPick: React.FC<PickProps> = ({ method, reservation }) => {
  const navigate = useNavigate();
  const [, updateState] = useDirectLinkState();
  const [hover, setHover] = useState<boolean>(false);

  const onEnter = () => setHover(true);
  const onLeave = () => setHover(false);
  const onClick = () => {
    updateState({
      payment_method: method.id,
      claim_body: {
        payment_method_id: method.id,
      },
    });

    if (reservation?.restaurant.type === 'event') {
      navigate('../confirm');
    } else {
      navigate(`../101`);
    }
  };

  const className = clsx(styles.scrollableItem, {
    [styles.hover]: hover,
  });

  return (
    <div
      className={className}
      onClick={onClick}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      <div className={clsx(styles.title, styles2.card)}>
        <span>{method.number}</span> <Card name={method.brand as CardNames} />
      </div>
      {method.nickname && <div className={styles.text}>{method.nickname}</div>}
    </div>
  );
};

export default PaymentPicker;
