import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import React, { Suspense, useState } from 'react';
import PhoneInputWithCountrySelect, { Country } from 'react-phone-number-input';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Header/Header.tsx';
import RestaurantName, {
  RestaurantNameLoader,
} from '../RestaurantName/RestaurantName.tsx';
import { isInfoReady, isPaymentReady } from '../lib/ready.ts';
import { UserAtom, useWidgetState } from '../lib/state.ts';
import { verify } from '../../../lib/api.ts';

import 'react-phone-number-input/style.css';
import styles from './Info.module.css';

const Info: React.FC = () => {
  const navigate = useNavigate();
  const [nexting, setNexting] = useState<boolean>(false);
  const [reservation, updateReservation] = useWidgetState();
  const user = useAtomValue(UserAtom);
  const payment_ready = isPaymentReady(reservation);
  const info_ready = isInfoReady(reservation);

  const onSubmit = (event: React.SyntheticEvent) => {
    // es-lint is finicky about promises and event handlers
    event.preventDefault();
    void onNext();
  };

  const onNext = async () => {
    switch (true) {
      case payment_ready:
        navigate('../confirm');
        break;
      case !!user.phone:
        navigate('../payment');
        break;
      default:
        if (info_ready) {
          try {
            setNexting(true);
            // Weird place for this, but make sure we don't have some old payment method set for a different user
            updateReservation({
              payment_method: undefined,
            });
            await verify(reservation.phone_number!);
            setNexting(false);
            navigate(`../phone`);
          } catch (e: unknown) {
            console.error(e);
            Sentry.captureException(e);
          }
        }
        break;
    }
  };

  const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateReservation({
      first_name: event.currentTarget.value,
    });
  };

  const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateReservation({
      last_name: event.currentTarget.value,
    });
  };

  const onPhoneCountryChange = (country: Country) => {
    updateReservation({
      phone_country: country,
    });
  };

  const onPhoneNumberChange = (number: string | undefined) => {
    updateReservation({
      phone_number: number,
    });
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateReservation({
      email: event.currentTarget.value,
    });
  };

  return (
    <>
      <Header title="Enter your details" step={2} show_image={false} />

      {reservation?.update_user_error && (
        <div className={clsx('content', styles.header_error)}>
          There was an issue with your details.
        </div>
      )}

      <div className="content">
        <Suspense fallback={<RestaurantNameLoader />}>
          <RestaurantName />
        </Suspense>

        <form className="section" onSubmit={onSubmit}>
          <Link to="../" className={styles.back}>
            {'<-'} Back
          </Link>

          <h3>Your details</h3>

          {reservation?.update_user_error && (
            <div className={styles.error}>{reservation?.update_user_error}</div>
          )}

          <div className={styles.name_field}>
            <label>Name on the reservation</label>

            <div className={styles.fields}>
              <input
                type="text"
                placeholder="First name"
                value={reservation.first_name ?? ''}
                onChange={onFirstNameChange}
                autoFocus={reservation.first_name === undefined}
              />
              <input
                type="text"
                placeholder="Last name"
                value={reservation.last_name ?? ''}
                onChange={onLastNameChange}
              />
            </div>
          </div>

          <div className={styles.field}>
            {reservation.phone_confirmed && (
              <>
                <label>Phone number (confirmed)</label>
                <input
                  type="text"
                  value={reservation.phone_number ?? ''}
                  readOnly={true}
                  disabled={true}
                />
              </>
            )}

            {!reservation.phone_confirmed && (
              <>
                <label>Phone number</label>
                <PhoneInputWithCountrySelect
                  placeholder="Enter phone number"
                  value={reservation.phone_number ?? ''}
                  country={reservation.phone_country ?? 'US'}
                  defaultCountry="US"
                  onChange={onPhoneNumberChange}
                  onCountryChange={onPhoneCountryChange}
                />
              </>
            )}
          </div>
          <div className={styles.field}>
            <label>Email address</label>
            <input
              type="email"
              placeholder="Enter email address"
              value={reservation.email ?? ''}
              onChange={onEmailChange}
            />
          </div>

          <input
            type="submit"
            className="nextButton"
            disabled={nexting}
            value={`Next${nexting ? '...' : ''}`}
          />
        </form>
      </div>
    </>
  );
};

export default Info;
