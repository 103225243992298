import styles from './Confirmation.module.css';
import { useState, useEffect } from 'react';

export function Link({ link }: { link: string }) {
  const [copy, setCopy] = useState(false);

  useEffect(() => {
    // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
    const timeoutId = setTimeout(() => {
      setCopy(false);
    }, 3000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [copy]); // Empty

  function copyClipboard() {
    navigator.clipboard.writeText(link);
    setCopy(true);
  }

  return (
    <div className={styles.linkcopiedsection}>
      <div className={styles.linkcopywrapper}>
        <div className={styles.linkitem} onClick={copyClipboard}>
          Invite by link
        </div>
      </div>
      <div className={styles.linkcopied}>{copy ? 'Link copied!' : null}</div>
    </div>
  );
}
